const INITIAL_STATE_APP = {
        daySelect: true,
        showDate: true,
        monthSelect: false,
        costCenterSelect: false,
        showAllStats: false,
        settings: false,
        showMulti: false,
        error: false,
		errorMsg: "",

}


export function appReducer (state = INITIAL_STATE_APP, action)  {
    const { type, payload } = action;
    switch(type) {
        case "DISPLAY_MONTHLY":
            return {
                ...state,
                daySelect: false,
                showDate: false,
                monthSelect: true,
                costCenterSelect: false,
                showAllStats: false,
                settings: false,
                showMulti: false
            }
        case "DISPLAY_DAILY":
            return {
                ...state,
                daySelect: true,
                showDate: true,
                monthSelect: false,
                costCenterSelect: false,
                showAllStats: false,
                settings: false,
                showMulti: false
            }
        case "DISPLAY_CC":
            return {
                ...state,
                daySelect: false,
                showDate: false,
                monthSelect: false,
                costCenterSelect: true,
                showAllStats: true,
                settings: false,
                showMulti: false
            }
        case "DISPLAY_SETTINGS":
            return {
                ...state,
                daySelect: false,
                showDate: false,
                monthSelect: false,
                costCenterSelect: false,
                showAllStats: false,
                settings: true,
                showMulti: false
            }
        case "MONTHLY_INPUT":
            return {
                ...state,
                multidata: payload,
                showAllStats: true
            }
        default:
            return state 
    }
} 
