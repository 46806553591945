//filter the notes authored by the user in question only
export default function(notes,userid){
    if(!notes)
        return [];
    return notes.filter(item=>{
        if(userid==parseInt(item.author.id)){
            return true;
        }else{
            return false;
        }
    });
}