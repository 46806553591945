//combine the obtained mappings to have the global mapping
module.exports = function(projectToIssue, issueToNotes, issueToProject) {
  let p2id = [];
  for (let pid in projectToIssue) {
    let project = projectToIssue[pid];
    for (let issue of project.issues) {
      let issueID = issue.id;
      let notes = issueToNotes[issueID];
      issue.notes = notes;
    }
  }
  return projectToIssue;
};
