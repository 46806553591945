//remove the issues which were closed before this month
export default function(issues, month, year) {
  console.log("foi", issues);
  if (!issues) return [];
  return issues.filter(issue => {
    if (issue.state == "opened") {
      return true;
    }
    let total = 0;
    for (let date = 1; date <= 31; date++) {
      total += issue.dateData[date];
    }
    if (total != 0) return true; // some work done in the month on the issue

    let closedDate = new Date(Date.parse(issue.closedAt));
    if (
      closedDate.getFullYear() < year ||
      (closedDate.getMonth() + 1 < month && closedDate.getFullYear() <= year)
    ) {
      //closed before
      return false;
    }
    return true;
  });
}
