//get the new projects from gitlab to be pushed into dynamodb

export default function(dynamoItems,gitlabItems){
     console.log("dynamo",dynamoItems);
     console.log("gitlab",gitlabItems);

     let items = [];

     for(let gItem of gitlabItems){
        let pid = gitlabItems.id; 
        let found = false;

        for(let dItem of dynamoItems){
            if(dItem.pid == gItem.id){
                found = true;
                break;
            }
        }
        if(!found){
            items.push(gItem);
        }
     }
     return items;
}