const INITIAL_STATE_COST = {
        ccload: false,
        ccData: [],
        ccError: "",
}


export function ccReducer (state = INITIAL_STATE_COST, action)  {
    const { type, payload } = action;
    switch(type) {
        case "CC_ERROR":
            return {
                ...state,
                ccload: true,
                ccError: payload,
            }
        case "CC_DATA":
            return {
                ...state,
                ccload: true,
                ccData: payload,
            }
        default:
            return state 
    }
} 