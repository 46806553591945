import React, { Component } from "react";
import { Store } from "idb-keyval";
import MultiReport from "../MultiReport";
import MiniSettingsForm from "../../components/MiniSettingsForm";

import { displayMonthly } from "../../Actions/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class URLMonthly extends Component {
  constructor(props) {
    super(props);
    this.customStore = new Store("gitlab-reporter-db", "gitlab-reporter-store");
    this.userids = [];
    this.names = [];

    this.month = parseInt(this.props.match.params.month);
    this.year = parseInt(this.props.match.params.year);

    this.allputData = [];
    let userParams = decodeURI(this.props.match.params.userids).split(",");

    this.userids = userParams.map(user => {
      return parseInt(user);
    });
    this.props.displayMonthly();
    if(this.props.match.params.tags){
      this.tags = decodeURI(this.props.match.params.tags).split(",");
      }
    else{
      this.tags = [];
    }
  }
  
  getUserIds() {
    let ids = [];
    for (let user of this.props.userReducer.users) {
      ids.push(user.id);
    }
    return ids;
  }
  getNames() {
    let names = [];
    for (let id of this.userids) {
      for (let user of this.props.userReducer.users) {
        if (id == user.id) {
          console.log("match", id, user);
          names.push(user.name);
        }
      }
    }
    return names;
  }

  handleBack() {
    this.props.history.push("/");
  }

  render() {
    if (this.props.userReducer.error) {
      return (
        <div>
          <div
            style={{
              padding: "2em"
            }}
            className="formerror"
          >
            ERROR<br />
            {this.props.userReducer.errorMsg}
          </div>
          <br />
          <MiniSettingsForm
            // base={this.props.userReducer.base}
            // pkey={this.props.userReducer.keyVal}
            // dispatch={this.handleMultiInput}
          />
        </div>
      );
    }

    if (!this.props.userReducer.loaded) {
      return (
        <div className="inputform prgrs">
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      );
    }
    this.names = this.getNames();
    console.log("names got", this.names);
    let multiData = {
      userids: this.userids,
      month: this.month,
      year: this.year,
      names: this.names,
      tags: this.tags,
    };
    console.log(multiData);
    return (
      <MultiReport
        history={this.props.history}
        goback={this.handleBack.bind(this)}
        data={multiData}
      />
    );
  }
}

const mapStateToProps = state => {
	 return{
			userReducer : state.userReducer,
	 } 
	}

const mapDispatchToProps = (dispatch) =>
		bindActionCreators({
      	displayMonthly
		}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(URLMonthly);