import { get, Store } from "idb-keyval";
function sanitizeString(str){
    let domain = str.split('/')[2];
    let nstr = domain.replace(/[,.]/g,'');
    console.log("domainstuff",nstr);
    return nstr;
}
export default function(){
    let customStore = new Store('gitlab-reporter-db', 'gitlab-reporter-store');
    return get('gitlab-base',customStore).then((base)=>{
        let sanitizedString = sanitizeString(base);
        console.log("sanitized",sanitizedString);
        return `RJSGitlabReporter-${sanitizedString}`;
    });
}