import {createStore,applyMiddleware,combineReducers} from 'redux';
import thunk from 'redux-thunk';
import { userReducer } from './Reducer/UserReducer';
import { appReducer  } from './Reducer/AppReducer';
import { ccReducer } from './Reducer/CCReducer';


const store = createStore(
  combineReducers({
    userReducer,
    appReducer,
    ccReducer
  }),
  applyMiddleware(thunk)
  )

export default store;