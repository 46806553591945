import React, { Component } from "react";
import Header from "../components/Header";
import AnchorLink from "react-anchor-link-smooth-scroll";
export default class About extends Component {
  render() {
    return (
      <div>
        <Header
          goback={() => {
            this.props.history.push("/");
          }}
          history={this.props.history}
          showMenu={true}
          legend={<span />}
          className="screenheader"
          text={"About"}
        />
        <div className="about">
          <div className="sidebar sidenav">
            <AnchorLink href="#intro">
              <div className="sidebar-link navlink">Intoduction</div>
            </AnchorLink>
            <AnchorLink href="#login">
              <div className="sidebar-link navlink">Login Screen</div>
            </AnchorLink>
            <AnchorLink href="#dailyr">
              <div className="sidebar-link navlink">Daily reports</div>
            </AnchorLink>
            <AnchorLink href="#monthlyr">
              <div className="sidebar-link navlink">Monthly Reports</div>
            </AnchorLink>
            <AnchorLink href="#ccr">
              <div className="sidebar-link navlink">Cost Centre Reports</div>
            </AnchorLink>
            <AnchorLink href="#settings">
              <div className="sidebar-link navlink">Settings</div>
            </AnchorLink>
          </div>

          <div className="abouttext">
            <div id="intro" />
            <h1> Introduction</h1>
            <p className="ytvideo">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/ebVH6nPSeQA"
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
              />
            </p>
            <p>
              Gitlab is a great software project management system for small and
              large teams. While using the community edition we found that it
              lacked some reporting features. This tool fills that gap in the
              following manner:
              <ul
                style={{
                  listStyle: "disc",
                  marginTop: "1rem"
                }}
              >
                <li>
                  Offers daily / monthly reports of time spent by each team
                  member on various issues.
                </li>

                <li>
                  Team members can view their daily task list across various
                  repositories. This helps them plan their day.
                </li>

                <li>
                  Important issues are flagged such as “time spent on issues
                  without a due date being allocated”, “due dates being
                  exceeded”
                </li>

                <li>
                  Management can see comparison of estimated hours vs. spent
                  hours.
                </li>

                <li>
                  Management can see reports of all issues handled by a team
                  member during a month along with their due date status.
                </li>

                <li>
                  Allows management to tag Repositories to cost centers. This
                  helps find out how much resource was allocated to each cost
                  center over a period.
                </li>

                <li>
                  If the issues are tagged as a Bug or Feature, then the cost
                  center report will automatically allocate the resource share.
                  A very useful feature for management to keep track of how the
                  development is progressing
                </li>
              </ul>
              <div class="alert alert-primary" role="alert">
                This tool makes reports on Gitlab issues which are labelled as
                “bugs” or “features” (case-insensitive) and then calculates the
                total time spent by the assignee over a period using the
                assignee’s spend notes in that period. Thus the net time spent
                per issue per day is not allowed to be less than zero.
              </div>
              <p>
                We welcome you to try our tool and urge you to submit your
                feedback about this tool by clicking{" "}
                <strong>Send Feedback</strong> in the drawer menu. Please read
                this About section to get a good overview of how this tool
                functions.
              </p>
            </p>
            <div id="login" />
            <h1>Login Screen</h1>
            <p className="ytvideo">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/JSIYcSjGo_g"
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
              />
            </p>
            <p>
              When you open the tool for the first time, you are presented with
              the login screen. Here, you must enter your{" "}
              <strong>Gitlab Personal Access Token</strong> and the Gitlab{" "}
              <strong>Base API</strong> URL. The token must have "<strong>
                API
              </strong>" permission, though it does not write any data.
            </p>
            <p>
              You can learn how to generate a Personal Access Token here:{" "}
              <a href="https://docs.gitlab.com/ee/user/profile/personal_access_tokens.html">
                https://docs.gitlab.com/ee/user/profile/personal_access_tokens.html.
              </a>{" "}
            </p>
            <p>
              A Personal Access Token allows the tool to get information about
              users and projects depending on the rights of the user generating
              it. A token generated from an account with Administrative rights
              may be able to see all data about all users and projects. Other
              tokens may only be able to see information about the account it is
              associated with and other related users.
            </p>
            <p>
              The Gitlab base API URL will depend on your domain name and Gitlab
              host. It will be something like:{" "}
            </p>

            <div
              style={{
                marginBottom: "1rem"
              }}
              className="ctd"
            >
              <code>https://&lt;YOUR_GITLAB_HOST&gt;/api/v4</code>
            </div>

            <p>
              For example, if your Gitlab is hosted at{" "}
              <span className="italics">gitlab.com/</span>, then the
              base API URL would be{" "}
              <span className="italics">https://gitlab.com/api/v4</span>.
              On entering the required information and clicking{" "}
              <strong>Submit</strong>, these will be saved in your browser
              locally and you won’t have to enter these details when you come
              back and use the tool again.
            </p>

            <div id="dailyr" />
            <h1>Daily Reports</h1>
            <p className="ytvideo">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/ypf83YyO02Y"
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
              />
            </p>
            <p>
              Daily Reports (accessed from <strong>Main Page ➡ Daily</strong>)
              may be generated for as many users as you wish. On leaving this
              field blank,the report for all the users will be generated.
            </p>

            <p>
              Next, you have to select the date. The default selected date is
              the last working day. So on a Monday, the last working day is
              assumed to be the previous Friday.
            </p>

            <p>
              {" "}
              After selecting a date, click <strong>Get Daily Report</strong> to
              generate the report. If the user’s name is highlighted by the pink
              color, it means that they haven’t logged any hours for that day.
              Issues highlighted in orange imply no due date. You can always
              consult the legend in the header as a reminder.
            </p>

            <p>
              The default daily report shows the hours logged by users on
              various issues, across various repositories for the particular day
              and the sum total of the hours. Notice, that the issues, where the
              user has not logged any hours won’t show up.
            </p>

            <p>
              In the Daily Reports tab,on clicking{" "}
              <strong>Get Issue List</strong>, the report generated will include
              the issues where the users have not logged any hours as well. This
              serves as a TODO list for each user. This is especially useful in
              case they are having issues spread across multiple repos. It only
              shows the issues that are open and assigned to them. This report
              has flags similar to the normal Daily Report.
            </p>

            <p>
              To get a printer friendly version, click the{" "}
              <strong>Download PDF</strong> button.
            </p>

            <div id="monthlyr" />
            <h1>Monthly Report</h1>
            <p className="ytvideo">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/l8mPmoMx3OI"
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
              />
            </p>
            <p>
              Monthly Reports (accessed from{" "}
              <strong>Main Page ➡ Monthly</strong>) may be generated for as many
              users as you wish. You must select at least one user.{" "}
            </p>

            <p>
              Next, you have to select the month and click{" "}
              <strong>Get Monthly Report</strong> to generate a report
            </p>

            <p>
              The monthly report gives a detailed report for the selected month.
              It shows the hours logged for each day under each issue.
            </p>

            <p>
              Issues highlighted with in orange imply they do not have a due
              date set. If an issue is not closed before its due date, the due
              date would be marked in red. If an issue is closed before or on
              the due date, it would be marked in green. You can always consult
              the legend in the header as a reminder.
            </p>

            <p>
              At the top right corner, below the header shows the share of time
              spent by the user on bug and features for each repository.
            </p>

            <div id="ccr" />
            <h1>Cost Centre Report</h1>
            <p className="ytvideo">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/nslBbn1-BqM"
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
              />
            </p>
            <p>
              Cost Center Reports (accessed from <strong>Main Page ➡ CC</strong>)
              may be generated for as many users as you wish. On leaving this
              field blank,the report for all the users will be generated.
            </p>

            <p>
              Next, you have to select the month and click{" "}
              <strong>Get CC Report</strong> to generate a report.{" "}
            </p>

            <p>
              This report shows the share of bugs and feature for each
              repository by each user. The Cost Centre report supports having
              Cost Centre tags for each repository. You can learn the steps on
              how to configure these <a href="#awssettings">here</a>.
            </p>

            <p>
              If you have already added the credentials, you may click{" "}
              <strong>Manage CC Database</strong>. In the screen that comes up,
              you assign Cost Centre tags to your projects. The default tag for
              any project is its name. When projects have the same Cost Center
              name, they are clubbed together in the Cost Centre report and the
              cost centre tags would be shown instead of the project names.{" "}
            </p>

            <p>
              The data for individual users can also be copied to clipboard or
              downloaded as a CSV file. You can also download a CSV file
              containing the report for all users by clicking the{" "}
              <strong>Download CSV</strong> button at the top right in the
              header.
            </p>

            <div id="settings" />
            <h1>Settings</h1>
            <p className="ytvideo">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/B-iTzZeJbnU"
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
              />
            </p>
            <p>
              The Settings (accessed from <strong>Main Page ➡ Settings</strong>)
              offers some customisations.{" "}
            </p>

            <p>
              You can change the <strong>Gitlab Personal Access Token</strong>{" "}
              and the Gitlab <strong>Base API</strong> URL.
            </p>
            <div id="awssettings" />
            <p>
              You can add or edit the <strong>AWS Access Key ID</strong> and{" "}
              <strong>AWS Secret Access Key</strong> required for the CC Report.
              After providing these credentials and pressing{" "}
              <strong>Save</strong>, the tool will create a table in your AWS
              DynamoDB with the format:
            </p>

            <div
              style={{
                marginBottom: "1rem"
              }}
              className="ctd"
            >
              <code>RJSGitlabReporter-&lt;YOUR DOMAIN&gt;</code>
            </div>

            <p>
              You can add usernames (such as bots) to be ignored while
              considering any report, or user selection menu.
            </p>

            <p>
              You may provide your company logo to get an internal look and feel
              in the <strong>Logo URL</strong> field.
            </p>

            <p>
              All settings and access keys/tokens are stored locally in the
              browser. Once you <strong>Log Out</strong>, all credentials will
              be cleared from the browser and you will need to provide them
              again to use the tool.
            </p>
            <div class="alert alert-primary" role="alert">
              IMPORTANT:<br /> Once created, the DynamoDB table is not
              automatically deleted. Please visit the AWS Console to do this.
            </div>
          </div>
        </div>
      </div>
    );
  }
}
