import React, { Component } from "react";

export default class LegendItem extends Component {
  render() {
    return (
      <div
        style={{
          margin: "4px"
        }}
      >
        <span
          style={{
            height: "14px",
            width: "14px",
            display: "inline-block",
            backgroundColor: this.props.color,
            border: "1px solid black"
          }}
        />
        <span
          style={{
            marginLeft: "10px"
          }}
        >
          {this.props.title}
        </span>
      </div>
    );
  }
}
