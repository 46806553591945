//Used to generate the PDF version of the daily report and download it
export default function dailyPrint(props) {

    let textmonths = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let doc = new jsPDF('p', 'pt', 'a4');
    doc.setDrawColor(0, 0, 0);
    let elems = document.querySelectorAll(".printable");

    let y = 0;
    let pxHeight = document.getElementById('test').offsetHeight;
    console.log("pxheight", pxHeight);
    let unitPx = 100 / pxHeight;
    let prevY = 0;
    let data = [];
    let cols;
    let i = 0;
    for (let elem of elems) {
        console.log(elem);
        let res = doc.autoTableHtmlToJson(elem);

        console.log("name", props.data.names[i]);

        let totalData = res.data[res.data.length - 1];
        let totalHours = parseInt(totalData[totalData.length - 1].innerText);

        console.log("totaldata", totalData, totalHours);

        let blankTd = document.createElement('td');
        blankTd.innerText = "Issues Handled";
        blankTd.className = 'blankelem';

        if (totalHours == 0) {
            blankTd.className = 'blankelemnw';
        }

        let td2 = document.createElement('td');
        td2.innerText = 'Estimate';

        let td3 = document.createElement('td');
        td3.innerText = 'Hours';

        data.push([blankTd, td2, td3]);
        // data.push([tdElemm]);
        data = data.concat(res.data);
        cols = res.columns;
        i += 1;

        console.log("res", res.columns, res.data);

        prevY = y;

    }
    let nwc = document.createElement('td');
    nwc.className = 'nwc';

    let nwt = document.createElement('td');
    nwt.className = 'nwt';
    nwt.innerText = "No records entered";

    let ndc = document.createElement('td');
    ndc.className = 'ndc';

    let ndt = document.createElement('td');
    ndt.className = 'ndt';
    ndt.innerText = 'No due date set';

    let legendTable = [nwc, nwt, ndc, ndt];

    console.log(data);
    let indx = 0;
    let boxCoords = [];
    let pillCoords = [];
    let redPills = [];
    let greenPills = [];

    let featPills = [];
    let bugPills = [];

    // let greyBorders = [];
    let pgNo = 1;
    let indentTexts = [];

    doc.autoTable(cols, data, {
        theme: "grid",
        styles:{
            overflow: 'visible'
        },
        drawRow: (row, data) => {
            console.log("row data", row.cells[0]);
            if (!row.cells[0].raw) {
                return;
            }



            row.cells[1].styles.halign = 'right';
            row.cells[2].styles.halign = 'right';

            console.log("classname", row.cells[0].raw.className);


            row.cells[0].styles.columnWidth = 10.0;
            row.cells[0].styles.overflow = 'visible';

            console.log("classlist", row.cells[0].raw.classList);
            for (let clname of row.cells[0].raw.classList) {
                console.log("classlist elem", clname);

                

                if (clname == 'openclose') {
                    console.log("classlist got", clname);
                    if (row.cells[0].text[0] == 'open') {
                        greenPills.push([data.settings.margin.left + 10, row.y + 5, doc.getTextDimensions("open").w, doc.getTextDimensions("open").h,
                        doc.getTextDimensions("open").h / 2, doc.getTextDimensions("open").h / 2, 'F']);
                    } else {
                        redPills.push([data.settings.margin.left + 10, row.y + 5, doc.getTextDimensions("closed").w + 2,
                        doc.getTextDimensions("closed").h, doc.getTextDimensions("closed").h / 2, doc.getTextDimensions("closed").h / 2, 'F']);
                    }
                    row.cells[0].text[0] = '';
                }
                if (clname == 'nodue') {
                    row.cells[0].styles.fillColor = [255, 204, 128];
                }

            }


            switch (row.cells[0].raw.className) {
                case 'blankelem':
                    console.log("blankelem");
                    for (let id in row.cells) {
                        row.cells[id].styles.fontStyle = 'bold';

                    }
                    doc.setFontSize(10);
                    doc.setTextColor(0, 0, 0);
                    doc.setFillColor(232, 234, 246);
                    doc.rect(data.settings.margin.left, row.y + 20, data.table.width, 20, 'DF');
                    doc.setFontType('bold');
                    doc.autoTableText(props.data.names[indx], data.settings.margin.left + 5, row.y + row.height / 2 + 20, {
                        halign: 'left',
                        valign: 'middle'
                    });

                    indx++;
                    data.cursor.y += 40;
                    break;

                case 'blankelemnw':
                    console.log("blankelemnw");
                    for (let id in row.cells) {
                        row.cells[id].styles.fontStyle = 'bold';

                    }
                    doc.setFontSize(10);
                    doc.setTextColor(0, 0, 0);
                    doc.setFillColor(255, 204, 188);
                    doc.rect(data.settings.margin.left, row.y + 20, data.table.width, 20, 'DF');
                    doc.setFontType('bold');
                    doc.autoTableText(props.data.names[indx], data.settings.margin.left + 5, row.y + row.height / 2 + 20, {
                        halign: 'left',
                        valign: 'middle'
                    });
                    indx++;
                    data.cursor.y += 40;
                    break;

                case 'projectname':
                    row.cells[0].styles.fontStyle = 'bold';
                    for (let id in row.cells) {
                        row.cells[id].styles.fillColor = [232, 234, 246];
                        row.cells[id].styles.lineColor = [232, 234, 246];
                        row.cells[id].styles.textColor = [0,0,0];

                    }
                    doc.setTextColor(0,0,0);
                    doc.setDrawColor(210, 210, 210);
                    boxCoords.push([data.settings.margin.left, row.y, data.table.width, row.height, 'S']);
                    break;

                case 'issuepdf':

                    let txt = "";
                    txt = row.cells[0].text[0];
                    txt = txt.trim();

                    console.log("tdtext",txt);

                    row.cells[0].styles.fontStyle = 'bold';


                    console.log("issuerow before", row.cells[0]);
                    let nexText;
                    if (txt.charAt(txt.length - 1) == 'e') {
                        let end = txt.length - 7;
                        nexText = txt.substring(0, end);

                        if(nexText.length>=75){
                            nexText = nexText.substr(0,75)+'...';
                        }

                        row.cells[0].text[0] = '';

                        featPills.push([data.settings.margin.left + 5 + doc.getStringUnitWidth(nexText, { fontStyle: 'Helvetica' }) * 12,
                        row.y + 5, doc.getTextDimensions("feature").w, doc.getTextDimensions(nexText).h, doc.getTextDimensions(nexText).h / 2
                            , doc.getTextDimensions(nexText).h / 2, 'F']);
                    } else {
                        
                        let end = txt.length - 3;
                        nexText = txt.substring(0, end);

                        if(nexText.length>=75){
                            nexText = nexText.substr(0,75)+'...';
                        }

                        row.cells[0].text[0] = '';

                        bugPills.push([data.settings.margin.left + 5 + doc.getStringUnitWidth(nexText, { fontStyle: 'Helvetica' }) * 12,
                        row.y + 5, doc.getTextDimensions("bug").w, doc.getTextDimensions(nexText).h, doc.getTextDimensions(nexText).h / 2
                            , doc.getTextDimensions(nexText).h / 2, 'F']);
                    }
                    console.log("textdimen", doc.getTextDimensions(nexText).w);



                    console.log("issuerow before", row.cells[0]);

                    for (let id in row.cells) {
                        row.cells[id].styles.fillColor = [255, 255, 255];
                        row.cells[id].styles.lineColor = [255, 255, 255];
                        //210,210,210

                    }
                    doc.setDrawColor(210, 210, 210);
                    indentTexts.push([nexText,data.settings.margin.left + 10,row.y+row.height/2 + doc.getTextDimensions(nexText).h/2 - 3]);
                    boxCoords.push([data.settings.margin.left, row.y, data.table.width, row.height, 'S']);
                    break;

            }

        },
        drawHeaderRow: (row, data) => {
            console.log("header", row, data);
            row.height = 5;
            for (let id in row.cells) {
                doc.setFontSize(0);
                row.cells[id].styles.lineWidth = 0;
                row.cells[id].styles.fillColor = [255, 255, 255];
                row.cells[id].styles.textColor = [255, 255, 255];
            }

            doc.setFontSize(18);
            doc.setTextColor(0, 0, 0);
            doc.setFillColor(255, 255, 255);

            doc.rect(data.settings.margin.left, row.y, data.table.width, 30, 'F');
            doc.autoTableText(`Report for ${props.data.onlydate}-${textmonths[props.data.month]}-${props.data.year}`,
                data.settings.margin.left + data.table.width / 2, row.y + row.height / 2, {
                    halign: 'center',
                    valign: 'middle'
                });
            doc.setFontSize(8);
            console.log("pagedata", data);
            doc.text('Page ' + pgNo.toString(), data.settings.margin.left, row.y + row.height / 2, {
                halign: 'center',
                valign: 'middle'
            });
            pgNo++;

            let now = new Date();

            let dateStr = `Generated At: ${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()} ${now.getHours()}:${now.getMinutes()}`;
            doc.text(dateStr,
                data.table.width + data.settings.margin.left - doc.getTextDimensions(dateStr).w,
                row.y + row.height / 2, {
                    halign: 'center',
                    valign: 'middle'
                });
            doc.setFillColor(255, 204, 128);
            doc.rect(data.settings.margin.left, row.y + 18, 10, 10, 'F');
            doc.text("No due date set", data.settings.margin.left + 13, row.y + 18+7.5);

            doc.setFillColor(255, 204, 188);
            doc.rect(data.settings.margin.left + 18 + doc.getTextDimensions("No due date set").w, row.y + 18, 10, 10, 'F');
            doc.text("No records entered", data.settings.margin.left + 31 + doc.getTextDimensions("No due date set").w, row.y + 18+7.5);


            data.cursor.y += 30;
        },
        addPageContent: (data) => {

            console.log("pagedata", data);
            for (let pill of pillCoords) {
                doc.rect(...pill);
            }
            //Issue Closed Pills
            for (let red of redPills) {
                doc.setFillColor(255, 82, 82);
                doc.roundedRect(...red);
                doc.setFontSize(8);
                doc.setTextColor(255, 255, 255);
                doc.text("closed", red[0] + 3, red[1] + 8);
            }
            //Issue Open Pills
            for (let green of greenPills) {
                doc.setFillColor(40, 167, 69);
                doc.roundedRect(...green);
                doc.setFontSize(8);
                doc.setTextColor(255, 255, 255);
                doc.text("open", green[0] + 2, green[1] + 8);
            }
            //Pills for bugs
            for (let bug of bugPills) {
                doc.setFillColor(255, 82, 82);
                doc.roundedRect(...bug);
                doc.setFontSize(8);
                doc.setTextColor(255, 255, 255);
                doc.text("bug", bug[0] + 2, bug[1] + 8);
            }
            //Pills for features
            for (let feat of featPills) {
                doc.setFillColor(83, 109, 254);
                doc.roundedRect(...feat);
                doc.setFontSize(8);
                doc.setTextColor(255, 255, 255);
                doc.text("feature", feat[0] + 3, feat[1] + 8);
            }
            for(let text of indentTexts){
                doc.setFontStyle('bold');
                doc.setFontSize(10);
                doc.setTextColor(0,0,0);
                doc.text(...text);
            }
            for (let box of boxCoords) {
                doc.setDrawColor(210, 210, 210);
                doc.rect(...box);
            }
            indentTexts = [];
            featPills = [];
            bugPills = [];
            pillCoords = [];
            redPills = [];
            greenPills = [];
            boxCoords = [];
        }
    });




    doc.save(`Report for ${props.data.onlydate}-${textmonths[props.data.month]}-${props.data.year}.pdf`);
}