//Parse the strings and compute time spent
export default function (notes) {
  let days = [];
  for (let i = 0; i <= 32; i++) {
    days[i] = 0;
  }
  if (!notes) return [];
  days[0] = -1;
  let remDate = -1;
  console.log("getdata", notes);
  for (let note of notes) {
    if (note.body == "removed time spent") {
      console.log(note.updated_at);
      let d = new Date(Date.parse(note.updated_at));
      console.log("rts_date", d.getDate());
      remDate = d.getDate();
    } else {
      if (!note.body) continue;

      let weekinfo = 0;
      if (note.body.match(/\d+w/i) != null) {
        weekinfo = parseInt(note.body.match(/\d+w/i)[0].slice(0, -1));
        console.log("weekinfo", weekinfo);
      }

      let dayinfo = 0;

      if (note.body.match(/\d+d/i) != null) {
        dayinfo = parseInt(note.body.match(/\d+d/i)[0].slice(0, -1));
        console.log("dayinfo", dayinfo);
      }

      let time = 0;
      if (note.body.match(/\d+h/i) != null) {
        time = parseInt(note.body.match(/\d+h/i)[0].slice(0, -1));
      }

      let minuteinfo = 0;
      if (note.body.match(/\d+m/i) != null) {
        minuteinfo = parseInt(note.body.match(/\d+m/i)[0].slice(0, -1));
      }

      // minuteinfo = (minuteinfo>0)?1:0;

      time = time + dayinfo * 8 + weekinfo * 40 + minuteinfo / 60;

      let type = note.body.match(/(subtracted|added)/)[0];
      let index = note.created_at.search(/T/i);
      let dates = note.created_at
        .substr(0, index)
        .split("-")
        .map((item) => parseInt(item));
      let date = dates[2];
      if (date == remDate) {
        console.log("found equal", date);
        continue;
      }
      switch (type) {
        case "added":
          days[date] += time;
          break;
        case "subtracted":
          days[date] -= time;
          console.log("subd", date, days[date], time);
          break;
      }

      // console.log(time,type,date);
    }
  }
  for (let date = 1; date <= 32; date++) {
    if (days[date] < 0) {
      days[date] = 0;
    }
  }
  return days;
}
