const INITIAL_STATE = {
    loaded: false,
    pnames : [],
    error : false,
    errorMsg: "Error reaching Gitlab. You might have got the Base URL wrong.",
    users : [],
    keyVal : "",
    base : "",
    is400 : false,
    firstLogin : false,
    multidata: {},
    reportElements: [],
    fullLoad: false,
}


export function userReducer (state = INITIAL_STATE, action)  {
    const { type, payload } = action;
    switch(type) {
        case "LOGIN_ERROR":
            return {
                ...state,
                error: true,
                errorMsg: "Please Log In",
                firstLogin: true,
                loaded: true
            }
        case "UNAUTHORIZED_USER":
            return {
                ...state,
                error: true,
                is400: true,
                errorMsg: "Check Gitlab Personal Acess Token",
                loaded: true
            }
        case "SHOW_ERROR":
            switch(payload) {
                case "BOTH":
                    return {
                        ...state,
                        error:true,
                        loaded:true
                    }
                case "ERROR":
                    return {
                        ...state,
                        error: true
                    }
                case "LOADED":
                    return {
                        ...state,
                        loaded: false
                    }
                default:
                    return state
            }
        case "GET_KEY_BASE":
            return{
                ...state,
                keyVal:payload[0],
                base:payload[1]
            }
        case "GET_ALL_USER":
            return {
                ...state,
                loaded : true,
                users : payload[0],
                pnames : payload[1]            
            }
        case "SET_REPORT":
            state.reportElements.push(payload);
            return {
                ...state           
            }
        case "EMPTY_REPORT":
            return {
                ...state,
                reportElements : []            
            }
        case "FULL_REPORT":
            return {
                ...state,
                fullLoad: true,
                fullReport: payload,            
            }
        case "EMPTY_FULL_REPORT":
            return {
                ...state,
                fullLoad: false,
                fullReport: [],            
            }
         default:
            return state 
    }
} 
