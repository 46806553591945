import React, { Component } from "react";
import config from "../config";
import DatePicker from "react-date-picker";
import Select from "react-select";
import { Link } from "react-router-dom";
import { emptyReport, emptyFullReport } from "../Actions/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class DateInputForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      users: [],
      loaded: false,
      username: "",
      name: "",
      onlydate: 1,
      error: false,
      errorMsg: "",
      startDate: {},
      selectusers: [],
      usernames: [],
      dateval: new Date(),
      tags: [],
    };
    config.KEY = this.props.pkey;
    config.BASE = this.props.base;
  }
  componentDidMount() {
    this.setState({ users: this.props.users, loaded: true, error: false });
    let yesterdate = new Date();
    yesterdate.setDate(yesterdate.getDate() - 1);
    let ymnth = yesterdate.getMonth() + 1;
    if (ymnth < 10) {
      ymnth = "0" + ymnth;
    }

    let yyear = yesterdate.getFullYear();

    if (yesterdate.getDay() == 0) {
      yesterdate.setDate(yesterdate.getDate() - 2);
    } else if (yesterdate.getDay() == 6) {
      yesterdate.setDate(yesterdate.getDate() - 1);
    }

    let yday = yesterdate.getDate();
    if (yday < 10) {
      yday = "0" + yday;
    }

    console.log("dayofweek", yesterdate.getDay());
    console.log("shoulddate cdm", `${yyear}-${ymnth}-${yday}`);
    this.setState(
      {
        loaded: true,
        date: `${yyear}-${ymnth}-${yday}`,
        startDate: yesterdate,
      },
      () => {
        this.filterUsers.call(this);
      }
    );
  }
  getUserIds() {
    let ids = [];
    for (let username of this.state.usernames) {
      for (let user of this.props.users) {
        if (username === user.username) {
          ids.push(user.id);
        }
      }
    }
    return ids;
  }
  filterUsers() {
    let usrs = this.props.users.filter((user) => {
      let createdDate = new Date(user.created_at);
      createdDate.setMilliseconds(0);
      createdDate.setSeconds(0);
      createdDate.setHours(0);
      createdDate.setMinutes(0);

      let nowDate = new Date(this.state.date);
      nowDate.setMilliseconds(0);
      nowDate.setSeconds(0);
      nowDate.setHours(0);
      nowDate.setMinutes(0);

      console.log("nowcreate", nowDate, createdDate);
      console.log("nowcreate", nowDate.getTime(), createdDate.getTime());
      if (nowDate.getTime() < createdDate.getTime()) {
        return false;
      } else {
        return true;
      }
    });
    console.log(usrs);
    this.setState({ users: usrs });
  }
  handleChange(val) {
    this.setState({ startDate: val });
    console.log("datechange", val);
  }
  handleSelectChange(values) {
    let usernames = values.map((value) => {
      return value.value;
    });

    let selVals = values.map((value) => {
      return {
        value: value.value,
        label: value.value,
      };
    });
    this.setState({ selectusers: selVals, usernames: usernames });
  }
  handleTagChange(event) {
    let tag = event.target.value.split(",");
    let tags = tag.map((value) => value.toLowerCase().trim()).join(",");
    this.setState({ tags: tags });
  }
  render() {
    if (this.state.error) {
      return (
        <div className="formerror">
          ERROR
          <br />
          {this.state.errorMsg}
        </div>
      );
    }
    let nowDate = new Date(Date.now());
    let mnth = nowDate.getMonth();
    let yr = nowDate.getFullYear();
    let dte = nowDate.getDate();

    mnth++;
    if (mnth < 10) {
      mnth = "0" + mnth;
    }

    if (dte < 10) {
      dte = "0" + dte;
    }
    console.log("limdate", `${yr}-${mnth}-${dte}`);
    if (!this.state.loaded) {
      return (
        <div className="inputform prgrs">
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            marginTop: "5%",
          }}
          className="card"
        >
          <table className="noshadow">
            <tbody>
              <tr className="noborder">
                <td className="noborder">Users:</td>
                <td className="noborder">
                  <span className="floatr">
                    <Select
                      className="selectspan"
                      placeholder="Search by name"
                      multi={true}
                      name="usernames"
                      value={this.state.selectusers}
                      onChange={this.handleSelectChange.bind(this)}
                      options={(() => {
                        let opts = [];
                        for (let user of this.props.users) {
                          opts.push({
                            value: user.username,
                            label: user.name,
                          });
                        }
                        return opts;
                      })()}
                    />
                  </span>
                </td>
              </tr>

              <tr className="noborder">
                <td className="noborder">{`${
                  this.props.tab == "daily" ? "Date:" : "Month:"
                }`}</td>
                <td className="noborder">
                  <div className="floatr forcer">
                    {this.props.tab == "daily" ? (
                      <DatePicker
                        className="pickerclass"
                        onChange={this.handleDateChange.bind(this)}
                        value={this.state.startDate}
                        maxDate={new Date()}
                      />
                    ) : (
                      <DatePicker
                        className="pickerclass"
                        onChange={(date) => {
                          this.setState({
                            month: date.getMonth() + 1,
                            year: date.getFullYear(),
                            dateval: date,
                          });
                        }}
                        value={this.state.dateval}
                        maxDate={new Date()}
                        maxDetail="year"
                      />
                    )}
                  </div>
                </td>
              </tr>
              {this.props.tab == "cc" || this.props.tab == "monthly" ? (
                <tr className="noborder">
                  <td className="noborder">Tag:</td>
                  <td className="noborder">
                    <span className="floatr">
                      <input
                        className="pickerclass"
                        placeholder="Eg: bug,feature"
                        multi={true}
                        name="usernames"
                        value={this.state.tags}
                        onChange={this.handleTagChange.bind(this)}
                      />
                    </span>
                  </td>
                </tr>
              ) : null}
              {this.props.tab == "monthly" ? (
                <tr className="noborder">
                  <td colSpan="2" className="noborder">
                    <button
                      onClick={this.handleMonthlySubmit.bind(this)}
                      className="btn submitter btn-primary"
                    >
                      Get Monthly Report
                    </button>
                  </td>
                </tr>
              ) : this.props.tab == "daily" ? (
                <>
                  <tr className="noborder">
                    <td colSpan="2" className="noborder">
                      <button
                        onClick={this.handleDateSubmit.bind(this)}
                        className="btn submitter btn-primary"
                      >
                        Get Daily Report
                      </button>
                    </td>
                  </tr>
                  <tr className="noborder">
                    <td colSpan="2" className="noborder">
                      <button
                        onClick={this.handleListSubmit.bind(this)}
                        className="btn submitter btn-primary"
                      >
                        Get Issue List
                      </button>
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr className="noborder">
                    <td className="noborder" align="center" colSpan="2">
                      <button
                        onClick={this.handleCCSubmit.bind(this)}
                        className="btn submitter btn-primary"
                      >
                        Get CC Report
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="noborder" colSpan="2">
                      <div className="dblink">
                        <Link to="/dbmanager">
                          <span className="linkdb">Manage CC Database</span>
                        </Link>
                      </div>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      );
    }
  }
  handleDateChange(value) {
    let setdate = `${value.getFullYear()}-${
      value.getMonth() + 1
    }-${value.getDate()}`;
    console.log("dateset", setdate);
    this.setState({ date: setdate, startDate: value }, () => {
      this.filterUsers.call(this);
    });
  }
  handleDateSubmit(evt) {
    this.props.emptyReport();
    console.log("shoulddate", this.state.date);
    let dateArray = this.state.date.split("-").map((item) => parseInt(item));

    console.log("datearray", dateArray);
    let usrString = this.getUserIds(this.state.usernames).join(",");

    if (this.state.usernames.length == 0) {
      this.setState(
        { month: dateArray[1], year: dateArray[0], onlydate: dateArray[2] },
        () => {
          this.props.history.push(
            `/daily/${this.state.year}/${this.state.month}/${this.state.onlydate}`
          );
          if (this.props.loadagain) {
            location.reload();
          }
          return;
        }
      );
      return;
    }

    this.setState(
      { month: dateArray[1], year: dateArray[0], onlydate: dateArray[2] },
      () => {
        this.props.history.push(
          `/daily/${this.state.year}/${this.state.month}/${this.state.onlydate}/${usrString}`
        );
        if (this.props.loadagain) {
          location.reload();
        }
      }
    );
  }
  handleListSubmit(evt) {
    this.props.emptyReport();
    console.log("shoulddate", this.state.date);
    let dateArray = this.state.date.split("-").map((item) => parseInt(item));

    console.log("datearray", dateArray);
    let usrString = this.getUserIds(this.state.usernames).join(",");

    if (this.state.usernames.length == 0) {
      this.setState(
        { month: dateArray[1], year: dateArray[0], onlydate: dateArray[2] },
        () => {
          console.log(
            "historypush",
            `/daily/${this.state.year}/${this.state.month}/${this.state.onlydate}/?issuelist=true`
          );
          this.props.history.push(
            `/daily/${this.state.year}/${this.state.month}/${this.state.onlydate}/?issuelist=true`
          );
          if (this.props.loadagain) {
            location.reload();
          }
        }
      );
      return;
    }

    this.setState(
      { month: dateArray[1], year: dateArray[0], onlydate: dateArray[2] },
      () => {
        this.props.history.push(
          `/daily/${this.state.year}/${this.state.month}/${this.state.onlydate}/${usrString}/?issuelist=true`
        );
        if (this.props.loadagain) {
          location.reload();
        }
      }
    );
  }

  handleMonthlySubmit(evt) {
    this.props.emptyReport();
    evt.preventDefault();
    console.log(this.state);
    if (this.state.year && this.state.usernames.length != 0) {
      if (this.state.tags.length > 0) {
        this.props.history.push(
          `/monthly/${this.state.year}/${
            this.state.month
          }/${this.getUserIds.call(this)}/${this.state.tags}`
        );
      } else {
        this.props.history.push(
          `/monthly/${this.state.year}/${
            this.state.month
          }/${this.getUserIds.call(this)}`
        );
      }
      if (this.props.loadagain) {
        location.reload();
      }
    } else {
      console.log("invalid");
    }
  }
  handleCCSubmit(evt) {
    this.props.emptyReport();
    this.props.emptyFullReport();
    console.log("shoulddate", this.state.date);
    let dateArray = this.state.date.split("-").map((item) => parseInt(item));
    console.log("datearray", dateArray);
    let usrString = this.getUserIds(this.state.usernames).join(",");

    if (this.state.usernames.length > 0) {
      if (this.state.tags.length > 0) {
        this.props.history.push(
          `/cc/${this.state.year}/${this.state.month}/${usrString}/${this.state.tags}`
        );
      } else {
        this.props.history.push(
          `/cc/${this.state.year}/${this.state.month}/${usrString}/`
        );
      }
    } else {
      if (this.state.tags.length > 0) {
        this.props.history.push(
          `/cc/${this.state.year}/${this.state.month}/allusers/${this.state.tags}`
        );
      } else {
        this.props.history.push(
          `/cc/${this.state.year}/${this.state.month}/allusers/`
        );
      }
    }
    if (this.props.loadagain) {
      location.reload();
    }
    return;
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      emptyReport,
      emptyFullReport,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(DateInputForm);
