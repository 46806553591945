import React from "react";
import ReactDOM from "react-dom";

import Nav from './containers/Nav';

import { Provider } from 'react-redux';
import store from './store'

var mountNode = document.getElementById("app");
ReactDOM.render(
    <Provider store={store}>
        <Nav/>
    </Provider>
    , mountNode);