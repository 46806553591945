import React, { Component } from "react";
import { Store, get } from "idb-keyval";
import MultiReport from "../MultiReport";
import MiniSettingsForm from "../../components/MiniSettingsForm";

import { connect } from "react-redux";

//to generate CC report for one or more users
class URLMultiDaily extends Component {
  constructor(props) {
    super(props);
    this.customStore = new Store("gitlab-reporter-db", "gitlab-reporter-store");
    this.userids = [];
    this.names = [];
    this.allputData = [];
    this.month = parseInt(this.props.match.params.month);
    this.year = parseInt(this.props.match.params.year);
    this.date = parseInt(this.props.match.params.date);

    if(this.props.match.params.userids){
      let userParams = decodeURI(this.props.match.params.userids).split(",");
      console.log("params", userParams);
      this.userids = userParams.map(user => {
        return parseInt(user);
      });
      this.names = this.getNamesFromIds();
    }
    else {
      this.userids = this.getUserIds();
      this.names = this.getNames();
    }
    
  }
  getUserIds() {
    let ids = [];
    for (let user of this.props.userReducer.users) {
      ids.push(user.id);
    }
    return ids;
  }
  getNames() {
    let names = [];
    for (let user of this.props.userReducer.users) {
      names.push(user.name);
    }
    return names;
  }
  getNamesFromIds() {
    let names = [];
    for (let id of this.userids) {
      for (let user of this.props.userReducer.users) {
        if (id == user.id) {
          console.log("match", id, user);
          names.push(user.name);
        }
      }
    }
    return names;
  }

  componentDidMount() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);

    let isl = params.get("issuelist");
    if (isl != null) {
      if (isl == "true") {
        this.issuelist = true;
      }
    }
    console.log("issuelist", this.issuelist);
  }
  handleBack() {
    this.props.history.push("/");
  }
  render() {
    if (this.props.userReducer.error) {
      return (
        <div>
          <div
            style={{
              padding: "2em"
            }}
            className="formerror"
          >
            ERROR<br />
            {this.props.userReducer.errorMsg}
          </div>
          <br />
          <MiniSettingsForm
            // base={this.props.userReducer.base}
            // pkey={this.props.userReducer.keyVal}
            // dispatch={this.handleMultiInput}
          />
        </div>
      );
    }
    if (!this.props.userReducer.loaded) {
      return (
        <div className="inputform prgrs">
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      );
    }
    let multiData = {
      userids: this.userids,
      onlydate: this.date,
      month: this.month,
      year: this.year,
      names: this.names
    };
    return (
      <MultiReport
        issuelist={this.issuelist}
        history={this.props.history}
        goback={this.handleBack.bind(this)}
        data={multiData}
      />
    );
  }
}

const mapStateToProps = state => {
	 return{
			userReducer : state.userReducer,
	 } 
	}

export default connect(mapStateToProps)(URLMultiDaily);