import React, { Component } from "react";
import Header from "../components/Header";

export default class Error404 extends Component {
  render() {
    return (
      <div>
        <Header
          showMenu={true}
          goback={() => {
            this.props.history.goBack();
          }}
          text="Gitlab Reporter"
          className="screenheader"
          legend={<span />}
        />
        <div
          style={{
            color: "#f44",
            margin: "10% auto",
            textAlign: "center"
          }}
        >
          <h1>404, Happens to the best of us 🔦 💻</h1>
        </div>
      </div>
    );
  }
}
