import React, { Component } from 'react'
import StatsDisplay from './StatsDisplay';
import config from '../config';
import { Store, get, set } from 'idb-keyval';

//Component to show each user in Cost Centre report 
export default class NamedStatsDisplay extends Component {
  constructor(props) {
    super(props);
    console.log("pnames in named stats disp", this.props.pnames);

    let projectNames = [];
    for (let pid in this.props.data) {
      projectNames[pid] = 'Project ' + pid;
    }
    let projectURLs = [];
    for (let pid in this.props.data) {
      projectURLs[pid] = '#';
    }
    let ccns = [];

    this.state = {
      projectNames: projectNames,
      projectURLs: projectURLs,
      ccNames: ccns,
      ccloaded: true
    };
    this.customStore = new Store('gitlab-reporter-db', 'gitlab-reporter-store');


    this.ccNames = [];

    for (let pid in this.props.data) {
      this.ccNames[pid] = this.props.pnames[pid].name;
    }

    get('gitlab-dynamo-access', this.customStore).then(access => {
      get('gitlab-dynamo-secret', this.customStore).then(secret => {
        get('gitlab-dynamo-endpoint', this.customStore).then(endpoint => {
          get('gitlab-dynamo-region', this.customStore).then(region => {
            AWS.config.update({
              region: region,
              endpoint: endpoint,
              accessKeyId: access,
              secretAccessKey: secret
            });
          });
        });
      });
    });
    config.key = this.props.pkey;
    console.log("named stats props", this.props);
    console.log("pnames in named stats disp", this.props.pnames);
  }

  render() {
    console.log("funcreport2", this.props.addreport);
    if (!this.state.ccloaded) {
      return (<div className="inputform prgrs">
        <div className="progress">
          <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}></div>
        </div>
      </div>);
    }
    for (let pid in this.props.ccdata) {
      this.ccNames[pid] = this.props.ccdata[pid];
    }
    console.log("pnames in named stats disp render", this.props.pnames);
    return (
      <div className="namedstatsdisplay">

        <StatsDisplay
          addreport={this.props.addreport}
          pnames={this.props.pnames}
          data={this.props.data}
          showName={true}
          name={this.props.name}
          unique={this.props.unique}
          ccdata={this.ccNames}
          ccloaded={this.state.ccloaded}
          tags={this.props.tags}
        />
      </div>
    )
  }
}
