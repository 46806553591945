import React, { Component } from "react";
import config from "../config";

export default class DisplayDayStats extends Component {
  constructor(props) {
    super(props);
    //userid

    console.log("daily-props", props);
    // console.log = ()=>{};

    let projectNames = [];
    for (let pid in this.props.data) {
      projectNames[pid] = "Project " + pid;
    }
    let projectURLs = [];
    for (let pid in this.props.data) {
      projectURLs[pid] = "#";
    }
    this.state = {
      projectNames: projectNames,
      projectURLs: projectURLs
    };
    console.log("date-obj", Date);
    config.BASE = this.props.base;
  }
  render() {
    let totalWorked = 0;
    this.contentElement = [];
    let indx = 0;
    for (let pid in this.props.data) {
      let project = this.props.data[pid];
      let innerElements = [];
      let inValid = true;
      for (let issue of project.issues) {
        if (!this.props.issuelist) {
          if (issue.dateData[this.props.date] != 0) {
            inValid = false;
          }
        } else {
          if (
            !(issue.state == "closed" && issue.dateData[this.props.date] == 0)
          ) {
            inValid = false;
          }
        }
      }
      if (!inValid)
        innerElements.push(
          <tr key={pid}>
            <td colSpan="3" className={"projectname"}>
              <a href={this.props.pnames[pid].url}>
                <div>{this.props.pnames[pid].name}</div>
              </a>
            </td>
          </tr>
        );
      indx++;
      let invalidCount = 0;
      for (let issue of project.issues) {
        if (!this.props.issuelist) {
          if (issue.dateData[this.props.date] == 0) {
            invalidCount++;

            continue;
          }
        } else {
          if (issue.state == "closed" && issue.dateData[this.props.date] == 0) {
            invalidCount++;

            continue;
          }
        }

        let tdElements = [];
        let clname = "badge badge-pill badge-primary";
        if (issue.type == "bug") {
          clname = "badge badge-pill badge-danger";
        }
        innerElements.push(
          <tr>
            <td key={issue.weburl} className="issuepdf" colSpan="34">
              <a href={issue.weburl}>
                <div>
                  <span style={{ paddingLeft: "7px" }} className="issuetitle">
                    {issue.title}
                  </span>
                  <span
                    className="type"
                    style={{ marginLeft: "7px" }}
                    className={clname}
                  >
                    {issue.type}
                  </span>
                </div>
              </a>
            </td>
          </tr>
        );
        let totalHours = 0;
        let dateElements = [];
        for (let day = 1; day <= 31; day++) {
          totalHours += issue.dateData[day];
          let ncn = "";
          let dta = issue.dateData[day] + "";
          dta = dta.substr(0, 4);
          if (day == this.props.date) {
            totalWorked += issue.dateData[day];
          }
          if (issue.dateData[day] == 0) {
            ncn = "greyout";
            dta = "-";
          }
          if (day == this.props.date) {
            dateElements.push(
              <td key={day} className={ncn + " rtd"} key={day}>
                {dta}
              </td>
            );
          }
        }
        let stateClass = "badge badge-pill badge-danger";
        if (issue.state == "opened") {
          issue.state = "open";
          stateClass = "badge badge-pill badge-success";
        }
        let td = (
          <td className={issue.flag + " openclose"}>
            <span className={stateClass}>{issue.state}</span>
          </td>
        );
        if (issue.flag && totalHours != 0) {
          td = (
            <td className="nodue openclose" style={{ background: "#FFCC80" }}>
              <span className={stateClass} style={{ marginLeft: "7px" }}>
                {issue.state}
              </span>
            </td>
          );
        } else {
          td = (
            <td className="openclose">
              <span className={stateClass} style={{ marginLeft: "7px" }}>
                {issue.state}
              </span>
            </td>
          );
        }
        tdElements.push(td);

        tdElements.push(
          <td key={issue.weburl} className="rtd">
            {issue.estimate}
          </td>
        );
        tdElements = tdElements.concat(dateElements);

        innerElements.push(<tr>{tdElements}</tr>);
      }

      this.contentElement.push(innerElements);
    }
    let warnSign = {};
    let warnclass = "";
    if (totalWorked == 0) {
      warnSign = {
        backgroundColor: "#FFCCBC"
      };
      warnclass = "warnpdf";
    }

    let th = (
      <th className={"nameth " + warnclass} style={warnSign} colSpan="3">
        {this.props.name}
      </th>
    );
    let thElements = [];

    thElements.push(th);
    let trElem = (
      <tr>
        <th>Issues Handled</th>
        <th className="rtd">Estimate</th>
        <th className="rtd">Hours</th>
      </tr>
    );
    thElements.push(trElem);
    totalWorked = Math.round(totalWorked);
    this.contentElement.push(
      <tr>
        <td className="thours projectname">Total Hours</td>
        <td />
        <td className="rtd">{totalWorked}</td>
      </tr>
    );
    return (
      <section className="daystats">
        <div />
        <div>
          <table className="printable dstable">
            <thead>{thElements}</thead>
            <tbody>{this.contentElement}</tbody>
          </table>
        </div>
      </section>
    );
  }
}
