import React, { Component } from "react";
import imageURL from "url:../rjs.png";
import { Link } from "react-router-dom";
import { get, Store } from "idb-keyval";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageURL: imageURL,
      sidebarOpen: false,
      overlayClass: "gone",
      sideLeft: "-45em",
      overlaydisp: "none"
    };
    this.customStore = new Store("gitlab-reporter-db", "gitlab-reporter-store");
    get("gitlab-imageurl", this.customStore).then(url => {
      if (!url) {
        return;
      }
      if (url != "") {
        this.setState({
          imageURL: url
        });
      }
    });
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }
  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }
  render() {
    let buttonEl;
    let sidebarContent;
    let overlay;
    if (this.props.showMenu) {
      buttonEl = (
        <button
          style={{ cursor: "pointer" }}
          className="menu-button"
          onClick={() => {
            window.addEventListener("click", evt => {
              if (evt.target.id == "overlay") {
                this.setState({
                  sidebarOpen: !this.state.sidebarOpen,
                  sideLeft: "-45em",
                  overlaydisp: "none"
                });
              }
            });
            this.setState({
              sidebarOpen: !this.state.sidebarOpen,
              sideLeft: "0em",
              overlaydisp: "block"
            });
          }}
        >
          <i className="fas fa-bars" />
        </button>
      );
      overlay = (
        <div
          id="overlay"
          style={{
            height: "100vh",
            width: "100vw",
            opacity: "0.5",
            background: "black",
            display: this.state.overlaydisp,
            position: "absolute",
            top: 0,
            left: 0
          }}
        />
      );

      sidebarContent = (
        <div
          style={{
            backgroundColor: "#fff",
            overflowY: "hidden",
            color: "black",
            textAlign: "left",
            height: "100%",
            overflow: "auto",
            position: "fixed",
            left: this.state.sideLeft,
            boxShadow: "0px 0px 9px 1px #555",
            top: "0px",
            transition: "all 0.3s",
            zIndex: "10"
          }}
        >
          <div className="sidebar-links">
            <Link to="/">
              <div className="sidebar-link">Reports</div>
            </Link>
            <Link to="/about">
              <div className="sidebar-link">About</div>
            </Link>
            <a target="_blank" href="https://goo.gl/forms/g4j3oPM3FKp8RHnE2">
              <div className="sidebar-link">Send Feedback</div>
            </a>
            <a target="_blank" href="https:\\www.rjs.in">
              <div className="sidebar-link">RJS</div>
            </a>
          </div>
        </div>
      );
    }

    return (
      <header className={this.props.className}>
        {sidebarContent}
        {buttonEl}
        {overlay}
        <div
          style={{
            background: "#fff",
            borderRadius: "50%",
            height: "44px",
            width: "44px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer"
          }}
          onClick={this.props.goback}
        >
          <img
            style={{
              height: "28px"
            }}
            src={this.state.imageURL}
          />
        </div>
        <h2
          style={{
            flexGrow: "1",
            textAlign: "left",
            paddingLeft: "0.5em"
          }}
        >
          {this.props.text}
        </h2>
        {this.props.legend}
      </header>
    );
  }
}
