import React, { Component } from "react";
import App from "../containers/App";
import DBManager from "../containers/DBManager";
import URLMonthly from "../containers/urlhandlers/URLMonthly";

import Error404 from "../containers/Erorr404";
import About from "../containers/About";

import { BrowserRouter as Router } from "react-router-dom";
import { Route, Switch } from "react-router";
import { Link } from "react-router-dom";

import URLMultiDaily from "../containers/urlhandlers/URLMultiDaily";
import URLMultiCC from "../containers/urlhandlers/URLMultiCC";

import { get, Store } from "idb-keyval";
import MiniSettings from "../components/MiniSettingsForm";
import Header from "../components/Header";

import Modal from "react-modal";
import axios from "axios";

import { logInError, UnauthorizedUser, showError, getKeyBase, getAllUsersStored } from "../Actions/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "4em"
  }
};

//Top Level nav component

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorModal: true,
      porgressShow: "Loading Users..."
    };
    this.allputData = [];
    this.customStore = new Store("gitlab-reporter-db", "gitlab-reporter-store");

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.projectLoaded = 0;
  }

  openModal() {
    this.setState({ errorModal: true });
  }

  closeModal() {
    this.setState({ errorModal: false });
  }

  getAllProjects() {
    let startPage = 1;
    this.setState({ porgressShow: "Loading Projects - 0%" });
    return axios
      .get(this.props.userRD.base + "/projects?per_page=100", {
        headers: {
          "PRIVATE-TOKEN": this.props.userRD.keyVal
        },
        onDownloadProgress: data => {
          console.log("progress", data);
        }
      })
      .then(response => {
        if (response.status == 401) {
          this.props.UnauthorizedUser();
          return;
        }
        if (response.status != 200) {
          this.props.showError("ERROR");
          return;
        }

        console.log("axios response", response.headers);
        console.log("axios response", response);

        this.projectNextPage = parseInt(response.headers["x-next-page"]);
        this.projectTotalPages = parseInt(response.headers["x-total-pages"]);

        this.projectLoaded += 100;
        let totalProjects = parseInt(response.headers["x-total"]);

        let perc = Math.round((this.projectLoaded / totalProjects) * 100);

        if (this.projectLoaded > totalProjects) {
          this.setState({ porgressShow: "Loading Projects - 100%" });
        } else {
          this.setState({ porgressShow: `Loading Projects - ${perc}%` });
        }

        for (let project of response.data) {
          this.allputData[project.id] = {};
          this.allputData[project.id].name = project.name;
          this.allputData[project.id].url = project.web_url;
          this.allputData[project.id].pid = project.pid;
          this.allputData[project.id].name_with_namespace =
            project.name_with_namespace;
        }

        if (this.projectTotalPages > startPage) {
          return this.getAllProjectPages();
        }
      });
    // .then(data => {

    // });
  }
  getAllProjectPages() {
    let allURL = [];
    for (let i = this.projectNextPage; i <= this.projectTotalPages; i++) {
      allURL.push(`${this.props.userRD.base}/projects?per_page=100&page=${i}`);
    }
    console.log("all", allURL);
    return Promise.all(
      allURL.map(url =>
        axios.get(url, {
          headers: {
            "PRIVATE-TOKEN": this.props.userRD.keyVal
          },
          onDownloadProgress: data => {
            console.log("progress", data);
          }
        })
      )
    ).then(resp => {
      console.log("response data", resp);

      for (let rs of resp) {
        let jsn = rs.data;

        this.projectLoaded += 100;
        let totalProjects = parseInt(rs.headers["x-total"]);

        let perc = Math.round((this.projectLoaded / totalProjects) * 100);

        if (this.projectLoaded > totalProjects) {
          this.setState({ porgressShow: "Loading Projects - 100%" });
        } else {
          this.setState({ porgressShow: `Loading Projects - ${perc}%` });
        }

        for (let j of jsn) {
          this.allputData[j.id] = {};
          this.allputData[j.id].name = j.name;
          this.allputData[j.id].url = j.web_url;
          this.allputData[j.id].pid = j.pid;
          this.allputData[j.id].name_with_namespace = j.name_with_namespace;
        }
      }
    });
    // .then(json => {
    //     console.log("prj-jsn", json);

    // });
  }

  getAllUsers(base) {
    this.props.showError("LOADED");
    return axios
      .get(base + "/users?per_page=100&order_by=name&sort=asc", {
        headers: {
          "PRIVATE-TOKEN": this.props.userRD.keyVal
        },
        onDownloadProgress: data => {
          console.log("progress", data);
        }
      })
      .then(res => {
        if (res.status == 401) {
          this.props.UnauthorizedUser();
          return;
        }
        if (res.status != 200) {
          this.props.showError("BOTH");
          return;
        }
        let data = res.data;
        get("gitlab-filter-users", this.customStore).then(filter => {
          let filteredNames = [];
          if (!filter) filter = " ";
          filteredNames = filter.split(",");

          filteredNames = filteredNames.map(item => {
            return item.trim();
          });

          console.log("filtered names", filteredNames);
          let newUsers = [];
          if (data) {
            console.log(data);
            newUsers = data.filter(user => {
              if (filteredNames.indexOf(user.username) == -1) {
                return true;
              } else return false;
            });
          } else {
            newUsers = [];
          }

          this.getAllProjects().then(() => {
            this.props.getAllUsersStored(newUsers,this.allputData);
          });
        });
      })
      .catch(err => {
        console.log("catch err", err);
        this.props.showError("BOTH");
        return;
      });
  }

  componentDidMount() {
    //All the data about projects and users are loaded here and passed down the components as props
    get("gitlab-pkey", this.customStore).then(val => {
      if (val) {
        console.log("key", val);
        get("gitlab-base", this.customStore).then(base => {
          console.log("fetching");
          this.props.getKeyBase(val,base);
          this.getAllUsers(base)
        });
      } else {
        this.props.logInError();
      }
    });
  }

  render() {
    if (this.props.userRD.error) {
      return (
        <div>
          <Router>
            <Switch>
              <Route
                exact
                path="/about"
                render={props => (
                  <About
                    {...props}
                  />
                )}
              />
              <Route
                render={props => {
                  console.log("error-state", this.props.userRD);

                  if (this.props.userRD.firstLogin) {
                    return (
                      <div>
                        <Header
                          history={this.props.history}
                          showMenu={true}
                          legend={<span />}
                          className="screenheader"
                          text={"Gitlab Reporter"}
                        />

                        <MiniSettings
                          // base={this.props.userRD.base}
                          // pkey={this.props.userRD.keyVal}
                          // dispatch={this.handleMultiInput}
                        />

                        <div className="ctd italics">
                          First time here ? Check out{" "}
                          <Link to="/about">
                            <span style={{ color: "#007bff" }}>the intro</span>
                          </Link>
                        </div>
                      </div>
                    );
                  }

                  return (
                    <div>
                      <Header
                        history={this.props.history}
                        showMenu={true}
                        legend={<span />}
                        className="screenheader"
                        text={"Gitlab Reporter"}
                      />

                      <Modal
                        isOpen={this.state.errorModal}
                        onRequestClose={this.closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                      >
                        <h2 className="ctd" style={{ color: "#f44" }}>
                          Error
                        </h2>

                        <h4 className="ctd">{this.props.userRD.errorMsg}</h4>

                        <button
                          onClick={this.closeModal}
                          style={{ float: "right" }}
                          className="btn btn-danger"
                        >
                          Okay, I understand
                        </button>
                      </Modal>

                      <MiniSettings
                        // base={this.props.userRD.base}
                        // pkey={this.props.userRD.keyVal}
                        // dispatch={this.handleMultiInput}
                      />
                    </div>
                  );
                }}
              />
            </Switch>
          </Router>
        </div>
      );
    }
    if (!this.props.userRD.loaded) {
      return (
        <div className="inputform prgrs">
          <div style={{ padding: "1em" }} className="ctd">
            {this.state.porgressShow}
          </div>
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      );
    }
    return (
      <Router>
        <Switch>
          {/*  */}
          <Route
            exact
            path="/"
            render={props => (
              <App
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/report"
            render={props => (
              <App
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/dbmanager"
            render={props => (
              <DBManager
                {...props}
                users={this.props.userRD.users}
                loaded={this.props.userRD.loaded}
                pnames={this.props.userRD.pnames}
                error={this.props.userRD.error}
                errorMsg={this.props.userRD.errorMsg}
              />
            )}
          />

          <Route
            exact
            path="/about"
            render={props => (
              <About
                {...props}
              />
            )}
          />

          <Route
            exact
            path="/daily/:year/:month/:date"
            render={props => (
              <URLMultiDaily
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/daily/:year/:month/:date/:userids"
            render={props => (
              <URLMultiDaily
                {...props}
              />
            )}
          />

          <Route
            exact
            path="/cc/:year/:month/:userids"
            render={props => (
              <URLMultiCC
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/cc/:year/:month/:userids/:tags"
            render={props => (
              <URLMultiCC
                {...props}
              />
            )}
          />

          <Route
            exact
            path="/monthly/:year/:month/:userids"
            render={props => (
              <URLMonthly
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/monthly/:year/:month/:userids/:tags"
            render={props => (
              <URLMonthly
                {...props}
              />
            )}
          />

          <Route component={Error404} />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = state => {
   return{
      userRD : state.userReducer
   } 
  }


const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
       getAllUsersStored,
       logInError,
       UnauthorizedUser,
       showError,
       getKeyBase
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Nav);