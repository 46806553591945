import filterUserNotes from './filterUserNotes';
import filterSpendNotes from './filterSpendNotes';
import filterMonthNotes from './filterMonthNotes';
import getDateData from './getDateData';
import getColorData from './getColorData';
import filterOpenIssue from './filterOpenIssue';
import filterCurrentIssues from './filterCurrentIssues';
//mother function to process the notes from global mapping using other util functions
export default function(mappping,userid,month,year){
    console.log("maping",mappping);
    for(let pid in mappping){
        let project = mappping[pid];
        for(let issue of project.issues){
            console.log(issue);
            let dueDate = issue.duedate;
            let estimate = issue.estimate;
            let state = issue.state;
            let closedAt = issue.closedAt;

            console.log("notes-issue",issue.notes);

            let userNotes = filterUserNotes(issue.notes,userid);
            let spendNotes = filterSpendNotes(userNotes);
            let monthNotes = filterMonthNotes(spendNotes,month,year);
            let dateData = getDateData(monthNotes);
            let colorData = getColorData(userNotes,dateData,month,year,dueDate,estimate,state,closedAt);

            console.log("month>",issue.title,monthNotes);
            issue.dateData = dateData;
            issue.colorData = colorData;
        }
        let currentIssues = filterCurrentIssues(project.issues,month,year);
        console.log("curri",currentIssues);
        let filterIssue = filterOpenIssue(currentIssues,month,year);
        project.issues = filterIssue;
        
        console.log("pris",filterIssue);
    }
    console.log("mpped");
    return mappping;
}