import React, { Component } from "react";
//AWS Region Options, edit the option value very carefully, they correspond to AWS Regions
export default class RegionOptions extends Component {
  render() {
    return (
      <select
        className="floatr"
        onChange={this.props.onChange}
        value={this.props.region}
      >
        <option value="us-east-2">US East (Ohio)</option>
        <option value="us-east-1">US East (N. Virginia)</option>
        <option value="us-west-1">US West (N. California)</option>
        <option value="us-west-2">US West (Oregon)</option>
        <option value="ap-northeast-1">Asia Pacific (Tokyo)</option>
        <option value="ap-northeast-2">Asia Pacific (Seoul)</option>
        <option value="ap-northeast-3">Asia Pacific (Osaka-Local)</option>
        <option value="ap-south-1">Asia Pacific (Mumbai)</option>
        <option value="ap-southeast-1">Asia Pacific (Sydney)</option>
        <option value="ca-central-1">Canada (Central)</option>
        <option value="cn-north-1">China (Beijing)</option>
        <option value="cn-northwest-1">China (Ningxia)</option>
        <option value="eu-central-1">EU (Frankfurt)</option>
        <option value="eu-west-1">EU (Ireland)</option>
        <option value="eu-west-2">EU (London)</option>
        <option value="eu-west-3">EU (Paris)</option>
        <option value="sa-east-1">South America (São Paulo)</option>
        <option value="us-gov-west-1">AWS GovCloud (US)</option>
      </select>
    );
  }
}
