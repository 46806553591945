//compute delete items after diffing items from dynamodb and gitlab
export default function getDeleteItems(dynamoItems,gitlabItems){
    console.log("dynamo",dynamoItems);
    console.log("gitlab",gitlabItems);

    let items = [];

    for(let dItem of dynamoItems){
       let found = false;

       for(let gItem of gitlabItems){
           if(dItem.pid == gItem.id){
               found = true;
               break;
           }
       }
       if(!found){
           items.push(dItem);
       }
    }
    return items;
}