import React, { Component } from "react";

import InputForm from "../components/InputForm";
import SettingsForm from "../components/SettingsForm";

import MiniSettingsForm from "../components/MiniSettingsForm";

import { Store, get, set } from "idb-keyval";

import "../styles/global.css";
import Header from "../components/Header";

import { displayMonthly, displayDaily, displayCostCenter, displaySettings, monthlyInput } from "../Actions/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// this handles everything in the "Reports" section
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      keyset: false,
      key: "",
      multidata: {},
      onlydate: -1,
      date: "",
      baseval: "",
      endpoint: "https://dynamodb.us-east-2.amazonaws.com",
      secret: "",
      access: "",
      region: "us-east-2",
      imageURL: "",
      filter: ""
    };

    this.customStore = new Store("gitlab-reporter-db", "gitlab-reporter-store");
  }

  componentDidMount() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);

    switch (params.get("tab")) {
      case "monthly":
        this.doMonthly();
        break;
      case "daily":
        this.doDaily();
        break;
      case "cc":
        this.doCC();
        break;
      case "settings":
        this.doSettings();
        break;
    }

    Promise.all([
      get("gitlab-pkey", this.customStore),
      get("gitlab-pkey", this.customStore),
      get("gitlab-dynamo-access", this.customStore),
      get("gitlab-dynamo-secret", this.customStore),
      get("gitlab-dynamo-endpoint", this.customStore),
      get("gitlab-dynamo-region", this.customStore)
    ]).then(data => {
      let val = data[0];
      let base = data[1];
      let access = data[2];
      let secret = data[3];
      let endpoint = data[4];
      let region = data[5];

      AWS.config.update({
        region: region,
        endpoint: endpoint,
        accessKeyId: access,
        secretAccessKey: secret
      });
    });
  }
  handleBack() {
    this.setState({
      showMulti: false
    });
  }

  doMonthly() {
    history.replaceState("", "Gitlab Reporter", "/?tab=monthly");
		this.props.displayMonthly();
  }
  doDaily() {
    history.replaceState("", "Gitlab Reporter", "/?tab=daily");
		this.props.displayDaily();
  }
  doCC() {
    history.replaceState("", "Gitlab Reporter", "/?tab=cc");
		this.props.displayCostCenter();
  }
  doSettings() {
    history.replaceState("", "Gitlab Reporter", "/?tab=settings");
		this.props.displaySettings();
  }
  hanldeFilterUsersChange(evt) {
    this.setState({ filter: evt.target.value });
  }
  render() {
    if (this.props.appReducer.error || this.props.userReducer.error) {
      return (
        <div>
          <div
            style={{
              padding: "2em"
            }}
            className="formerror"
          >
            ERROR<br />
            {this.props.appReducer.errorMsg}
          </div>
          <br />
          <MiniSettingsForm />
        </div>
      );
    }
    if (!this.props.userReducer.loaded) {
      return (
        <div className="inputform prgrs">
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      );
    }
    console.log("app-state", this.props.userReducer.users);
    return (
      <div>
        <Header
          history={this.props.history}
          showMenu={true}
          legend={<span />}
          className="screenheader"
          text={"Gitlab Reporter"}
        />
        <div className="inputform tablayout">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={`nav-link ${this.props.appReducer.daySelect? "active" : ""}`}
                onClick={evt => {
                  this.doDaily();
                }}
              >
                Daily
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${this.props.appReducer.monthSelect? "active" : ""}`}
                onClick={evt => {
                  this.doMonthly();
                }}
              >
                Monthly
              </button>
            </li>

            <li className="nav-item">
              <button 
                className={`nav-link ${this.props.appReducer.costCenterSelect? "active" : ""}`}                
                onClick={evt => {
                  this.doCC();
                }}
              >
                Cost Centre
              </button>
            </li>
            <li>
              <button 
                className={`nav-link ${this.props.appReducer.settings? "active" : ""}`}
                onClick={evt => {
                  this.doSettings();
                }}
              >
                Settings
              </button>
            </li>
          </ul>
          {
            this.props.appReducer.settings
            ?
            <SettingsForm
              history={this.props.history}
              users={this.props.userReducer.users}
              base={this.state.base}
              pkey={this.state.key}
            />
            :
            <InputForm
              loadagain={false}
              tab={`${this.props.appReducer.costCenterSelect ? "cc" 
                : this.props.appReducer.monthSelect ? "monthly" 
                : "daily"}`
              }
              history={this.props.history}
              users={this.props.userReducer.users}
            />
          }
        </div>
      </div>
    );
  }
  handleMonthlyInput(data) {
		this.props.monthlyInput(data);
    	this.setState({ multidata: data, showAllStats: true });
  }
}

const mapStateToProps = state => {
	 return{
			userReducer : state.userReducer,
			appReducer : state.appReducer
	 } 
	}


const mapDispatchToProps = (dispatch) =>
		bindActionCreators({
			displayMonthly,
			displayDaily,
			displayCostCenter,
			displaySettings,
			monthlyInput
		}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);