import config from '../config';
import getTableName from './getTableName';
export default function(callback){
    let docClient = new AWS.DynamoDB.DocumentClient();
    getTableName().then(tablename=>{
        console.log("tablename",tablename);
        let params = {
            TableName: tablename,
        };
    
        docClient.scan(params,callback);
    });
    
}