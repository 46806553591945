import React, { Component } from "react";
import getAllData from "../awsutil/getAllData";
import putAllData from "../awsutil/putAllData";
import { Store, get, set } from "idb-keyval";
import getNewItems from "../util/getNewItems";
import Header from "../components/Header";
import updateData from "../awsutil/updateData";
import sortItems from "../util/sortItems";
import SettingsForm from "../components/SettingsForm";
import deleteData from "../awsutil/deleteData";
import getDeleteItems from "../util/getDeleteItems";

export default class DBManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      loading: true,
      key: "",
      base: "",
      ccNames: [],
      saveButton: "Save",
      saving: false,
      error: false,
      errorMsg: "",
      changed: [],
      pdata: this.props.pnames,
      itemMap: [],
      delDone: false,
      progressShow: "Loading Data from Dynamo DB"
    };

    this.customStore = new Store("gitlab-reporter-db", "gitlab-reporter-store");
    this.projectNextPage = -1;
    this.projectTotalPages = -1;
    this.allputData = [];
    this.putItems = [];
    this.config = {};
    this.prData = [];

    for (let pid in this.props.pnames) {
      let project = this.props.pnames[pid];
      this.prData.push({
        projectname: project.name_with_namespace,
        pid: pid
      });
      this.allputData.push({
        id: pid,
        nwm: project.name_with_namespace,
        name: project.name
      });
    }
  }
  refresh() {
    getAllData(this.dynamoScanCallback.bind(this));
  }
  componentDidMount() {
    Promise.all([
      get("gitlab-dynamo-access", this.customStore),
      get("gitlab-dynamo-secret", this.customStore),
      get("gitlab-dynamo-endpoint", this.customStore),
      get("gitlab-dynamo-region", this.customStore)
    ]).then(data => {
      let access = data[0];
      let secret = data[1];
      let endpoint = data[2];
      let region = data[3];

      AWS.config.update({
        region: region,
        endpoint: endpoint,
        accessKeyId: access,
        secretAccessKey: secret
      });
      this.refresh.call(this);
    });
  }
  dynamoScanCallback(err, data) {
    if (err) {
      console.log("scann error", err);
      this.setState({ error: true, errorMsg: "Check AWS Credentials" });
      return;
    } else {
      console.log("scan succed", data);
      this.setState({ items: data.Items });
      get("gitlab-pkey", this.customStore).then(key => {
        console.log("idb", key);
        if (key) {
          get("gitlab-base", this.customStore).then(base => {
            console.log("base", base);

            this.setState({ key: key, keyset: true, base: base }, () => {
              this.setState({ pdata: sortItems(this.prData) });
              console.log("prdata", this.prData);

              this.putItems = getNewItems(this.state.items, this.allputData);
              this.delItems = getDeleteItems(this.state.items, this.allputData);
              console.log("delitems", this.delItems);
              if (this.delItems.length == 0) {
                this.setState({ delDone: true });
              }
              for (let item of this.delItems) {
                deleteData(item.pid, (err, data) => {
                  if (err) {
                    console.log("deleted err");
                  } else {
                    console.log("deleted", item.pid);
                  }
                  this.setState({ delDone: true });
                });
              }

              console.log("put1", this.putItems, this.state.items);

              if (this.putItems.length == 0) {
                let itms = sortItems(this.state.items);
                let imap = [];
                for (let item of itms) {
                  imap[item.pid] = item.ccname;
                }
                this.setState({ loading: false, itemMap: imap, items: itms });
                this.setCCNames();

                return;
              }
              this.setState({
                progressShow: "Transfering Gitlab data to DynamoDB"
              });
              putAllData(this.putItems, this.dynamoPutCallback.bind(this));
              console.log("put", this.putItems);
            });
          });
        } else {
        }
      });
    }
  }
  dynamoPutCallback(err, data, i, num) {
    let total = this.putItems.length;
    if (err) {
      console.log(err);
    } else {
      console.log("put", i);
      if (num == total) {
        console.log("all put");
        getAllData((err, data) => {
          if (err) return;
          else {
            //sort this.state.items
            let itms = sortItems(data.Items);
            let imap = [];
            for (let item of itms) {
              imap[item.pid] = item.ccname;
            }
            this.setState(
              { items: itms, itemMap: imap, loading: false },
              () => {
                this.setCCNames();
              }
            );
          }
        });
      }
    }
  }
  setCCNames() {
    console.log("sccn called");
    let ccN = [...this.state.ccNames];
    for (let item of this.state.items) {
      console.log(item.pid);
      ccN[item.pid] = {};
      ccN[item.pid].ccname = item.ccname;
      ccN[item.pid].updated = false;
    }
    console.log("ccn", ccN);
    this.setState({ ccNames: ccN });
  }

  render() {
    if (this.state.error) {
      return (
        <div>
          <div
            style={{
              padding: "2em"
            }}
            className="formerror"
          >
            ERROR<br />
            {this.state.errorMsg}
          </div>
          <br />
          <SettingsForm
            base={this.state.base}
            pkey={this.state.key}
            dispatch={this.handleMultiInput}
          />
        </div>
      );
    }

    if (this.state.loading) {
      return (
        <div className="inputform prgrs">
          <div style={{ padding: "1em" }} className="ctd">
            {this.state.progressShow}
          </div>
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      );
    }
    if (this.state.ccNames.length == 0) {
      return <div>load</div>;
    }
    let delLoader = <span />;
    if (!this.state.delDone) {
      delLoader = (
        <span
          style={{
            position: "absolute",
            top: "4em",
            left: "4em",
            fontWeight: "bold",
            color: "#555"
          }}
        >
          Deleting Some Items
        </span>
      );
    }
    console.log("ccnames", this.state.ccNames);
    let trData = this.state.pdata.map(item => {
      return (
        <tr className="alternate noborder" key={item.pid}>
          <td className="noborder">
            {item.projectname}
            <br />
            <span
              style={{
                fontSize: "0.9em",
                fontWeight: "bold"
              }}
            >
              Project ID : {item.pid}
            </span>
          </td>
          <td className="noborder">
            <input
              style={{
                borderColor: this.state.changed[item.pid] + ""
              }}
              type="text"
              className="rtd nomarg"
              onChange={evt => {
                console.log(evt.target.value);
                let ccN = [...this.state.ccNames];
                ccN[item.pid].ccname = evt.target.value;
                ccN[item.pid].updated = true;

                let chngd = [...this.state.changed];
                chngd[item.pid] = "#f44";
                this.setState({ ccNames: ccN, changed: chngd });
              }}
              onBlur={evt => {
                console.log("bluured", evt.target.value);
                console.log("updated", this.state.ccNames[item.pid]);
                let val = evt.target.value;
                if (this.state.ccNames[item.pid].updated) {
                  updateData(item.pid, evt.target.value, (err, data) => {
                    if (err) {
                      console.log("update error", err);
                    } else {
                      let ccN = [...this.state.ccNames];
                      ccN[item.pid].ccname = val;
                      ccN[item.pid].updated = false;

                      let chngd = [...this.state.changed];
                      chngd[item.pid] = "#64DD17";
                      this.setState({ changed: chngd });
                    }
                  });
                }
              }}
              value={this.state.ccNames[item.pid].ccname}
            />
          </td>
        </tr>
      );
    });
    console.log("item map", this.state.itemMap);
    return (
      <div>
        <Header
          showMenu={true}
          goback={() => {
            this.props.history.goBack();
          }}
          text="Cost Centre Database"
          className="screenheader"
          legend={<span />}
        />
        <table
          style={{
            marginTop: "6em"
          }}
          className="noborder"
        >
          <thead>
            <tr>
              <th className="noborder">Project</th>
              <th className="rtd noborder">Cost Centre Name</th>
            </tr>
          </thead>
          <tbody>{trData}</tbody>
        </table>
        {delLoader}
      </div>
    );
  }
}
