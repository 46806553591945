//filter out all issues opened after this month
export default function(issues, month, year) {
  if (!issues) {
    return [];
  }
  return issues.filter(issue => {
    let openedDate = new Date(Date.parse(issue.openedAt));
    openedDate.setHours(0);
    openedDate.setMinutes(0);
    openedDate.setSeconds(0);
    openedDate.setMilliseconds(0);
    console.log(
      "datecomp",
      month,
      year,
      openedDate.getMonth(),
      openedDate.getFullYear()
    );
    if (
      year < openedDate.getFullYear() ||
      (year <= openedDate.getFullYear() && month < openedDate.getMonth() + 1)
    ) {
      console.log("datecomp");
      return false;
    } else {
      return true;
    }
  });
}
