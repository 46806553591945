import config from '../config';
import getTableName from './getTableName';

export default function (projects, callback) {

    let docClient = new AWS.DynamoDB.DocumentClient();
    let lngth = projects.length;
    let num = 0;

    console.log("projects-put",projects);

    getTableName().then(tableName=>{
        projects.forEach(project => {
            console.log("pid>>",project.id);
            let params = {
                TableName: tableName,
                // Key: {
                //     "pid": project.pid,
                // },
                Item: {
                    "pid": parseInt(project.id),
                    "projectname": project.nwm,
                    "ccname": project.name
                }
            }
            docClient.put(params,(err,data)=>{
                num++;
                callback(err,data,project.id,num);
            });
        });
    });
    
}