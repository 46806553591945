export default function(notes, month, year) {
  if (!notes) return [];
  return notes.filter(note => {
    if (note.body === "removed time spent") {
      let d = new Date(Date.parse(note.updated_at));
      return d.getMonth() + 1 == month && d.getFullYear() == year;
    } else {
      let index = note.created_at.search(/T/i);

      let dates = note.created_at
        .substr(0, index)
        .split("-")
        .map(item => parseInt(item));
      if (dates[0] === year && dates[1] === month) {
        return true;
      } else {
        return false;
      }
    }
  });
}
