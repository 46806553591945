import config from "../config";
//compute the colors for each cell
export default function (
  userNotes,
  dateData,
  month,
  year,
  dueDate,
  estimate,
  state,
  closedAt
) {
  //find estimate date
  let colorData = [];
  for (let i = 0; i <= 31; i++) {
    colorData.push("#fff");
  }
  colorData[0] = null;
  for (let note of userNotes) {
    let str = "starts";
    console.log("gcd", note.body);
    if (note.body.startsWith("changed time estimate") && note.system) {
      break;
    }
  }
  let dateDue = new Date(Date.parse(dueDate));
  dateDue.setHours(0);
  dateDue.setMinutes(0);
  let closedDate = new Date(Date.parse(closedAt));
  closedDate.setHours(0);
  closedDate.setMinutes(0);
  closedDate.setSeconds(0);
  closedDate.setMilliseconds(0);
  console.log("duedate", dateDue);
  for (let day = 1; day <= 31; day++) {
    let hours = dateData[day];
    let curDate = new Date(year, month - 1, day);
    let todate = new Date();

    todate.setHours(0);
    todate.setMinutes(0);
    todate.setSeconds(0);
    todate.setMilliseconds(0);

    if (curDate.getTime() == dateDue.getTime()) {
      if (state == "closed") {
        console.log("cd", closedDate);
        console.log("cd", curDate);
        if (curDate.getTime() < closedDate.getTime()) {
          console.log("timeclose", curDate.getTime(), closedDate.getTime());

          colorData[day] = config.colors.duemiss;
        } else colorData[day] = config.colors.duemet;
      } else {
        if (todate.getTime() < curDate.getTime()) {
          colorData[day] = config.colors.dueon;
        } else colorData[day] = config.colors.duemiss;
      }
      continue;
    }

    if (hours == 0) {
      continue;
    }

    if (dueDate == null && estimate == null) {
      //do nothing
    } else if (dueDate == null) {
    } else {
      //estimate is null, due present
      if (curDate.getTime() <= dateDue.getTime()) {
        console.log("wdne");
        colorData[day] = config.colors.wdne;
      } else {
        console.log("odne");
        colorData[day] = config.colors.odne;
      }
    }
  }
  console.log("clrd", colorData);
  return colorData;
}
