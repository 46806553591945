import React, { Component } from "react";
import { Store, get, set, clear } from "idb-keyval";
import RegionOptions from "./RegionOptions";
import Select from "react-select";
import "react-select/dist/react-select.css";
import createTable from "../awsutil/createTable";

import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "4em"
  }
};

export default class SettingsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "",
      baseval: "",
      endpoint: "https://dynamodb." + "us-east-2" + ".amazonaws.com",
      secret: "",
      access: "",
      region: "us-east-2",
      baseval: "",
      imageURL: "",
      filter: "",
      selectusers: [],
      btnval: "Submit",
      confirmModal: false,
      logout: false
    };
    this.customStore = new Store("gitlab-reporter-db", "gitlab-reporter-store");
  }

  openModal(evt) {
    evt.preventDefault();
    console.log("lg click");
    this.setState({ confirmModal: true });
  }

  closeModal(logout) {
    this.setState({ confirmModal: false, logout: logout });
  }

  componentDidMount() {
    Promise.all([
      get("gitlab-pkey", this.customStore),
      get("gitlab-base", this.customStore),
      get("gitlab-dynamo-access", this.customStore),
      get("gitlab-dynamo-secret", this.customStore),
      get("gitlab-dynamo-endpoint", this.customStore),
      get("gitlab-dynamo-region", this.customStore),
      get("gitlab-imageurl", this.customStore),
      get("gitlab-filter-users", this.customStore)
    ]).then(data => {
      console.log("promise data", data);
      let key = data[0];
      let base = data[1];
      let access = data[2];
      let secret = data[3];
      let endpoint = data[4];
      let region = data[5];
      let url = data[6];
      let users = data[7];
      let filter = data[7];

      if (!region) {
        region = "us-east-2";
        endpoint = "https://dynamodb." + "us-east-2" + ".amazonaws.com";
      }
      let fillvals = [];
      if (users) {
        let userNames = users.split(",");
        for (let un of userNames) {
          if (un == "") {
            continue;
          }
          fillvals.push({
            value: un,
            label: un
          });
        }
      }

      this.setState({
        key: key,
        baseval: base,
        endpoint: endpoint,
        secret: secret,
        access: access,
        region: region,
        base: base,
        imageURL: url,
        filter: filter,
        selectusers: fillvals
      });
    });
  }
  hanldeKeySubmit(evt) {
    evt.preventDefault();
    console.log("state>", this.state);

    Promise.all([
      set("gitlab-pkey", this.state.key, this.customStore),
      set("gitlab-base", this.state.base, this.customStore),
      set("gitlab-dynamo-access", this.state.access, this.customStore),
      set("gitlab-dynamo-secret", this.state.secret, this.customStore),
      set("gitlab-dynamo-endpoint", this.state.endpoint, this.customStore),
      set("gitlab-dynamo-region", this.state.region, this.customStore),
      set("gitlab-imageurl", this.state.imageURL, this.customStore),
      set("gitlab-filter-users", this.state.filter, this.customStore)
    ]).then(() => {
      console.log("states", this.state);
      AWS.config.update({
        region: this.state.region,
        endpoint: this.state.endpoint,
        accessKeyId: this.state.access,
        secretAccessKey: this.state.secret
      });
      console.log("config", AWS.config);
      let dynamodb = new AWS.DynamoDB();

      this.setState({ btnval: "Loading..." });
      createTable(dynamodb, () => {
        this.setState({ btnval: "Submit" });
        location.replace("/?tab=settings");
      });
    });
  }
  handleKeyChange(evt) {
    this.setState({ key: evt.target.value.trim() });
  }
  handleBaseChange(evt) {
    this.setState({
      baseval: evt.target.value.trim(),
      base: evt.target.value.trim()
    });
  }
  handleEndpointChange(evt) {
    this.setState({ endpoint: evt.target.value });
  }
  handleSecretChange(evt) {
    this.setState({ secret: evt.target.value.trim() });
  }
  handleAccessChange(evt) {
    this.setState({ access: evt.target.value.trim() });
  }
  handleRegionChange(evt) {
    console.log("regionchange", evt.target.value);
    let endpoint = "https://dynamodb." + evt.target.value + ".amazonaws.com";
    console.log(endpoint);
    this.setState({ region: evt.target.value, endpoint: endpoint });
  }
  handleImageURLChange(evt) {
    this.setState({ imageURL: evt.target.value.trim() });
  }
  hanldeFilterUsersChange(evt) {
    this.setState({ filter: evt.target.value });
  }
  handleLogOut(evt) {
    // evt.preventDefault();
    // if (!confirm("Are you sure?")) {
    // 	console.log("Here!!!");
    // 	console.clear();
    // 	return;
    // }
    this.setState({
      key: "",
      baseval: "",
      endpoint: "",
      secret: "",
      access: "",
      region: "us-east-2",
      baseval: "",
      imageURL: "",
      filter: ""
    });
    clear(this.customStore);
    location.reload();
  }
  handleSelectChange(values) {
    let fltr = "";
    values.forEach(element => {
      fltr += `${element.value},`;
    });

    let selVals = values.map(value => {
      return {
        value: value.value,
        label: value.value
      };
    });
    this.setState({ selectusers: selVals, filter: fltr });
  }
  render() {
    if (this.state.logout) {
      this.handleLogOut.call(this);
      return <span />;
    }

    return (
      <div
        style={{
          marginTop: "5%"
        }}
        className="card"
      >
        <Modal
          isOpen={this.state.confirmModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h2 className="ctd" style={{ color: "#f44" }}>
            Are you sure?
          </h2>

          <h4 className="ctd">{this.state.errorMsg}</h4>

          <button
            onClick={() => {
              this.closeModal(true);
            }}
            style={{ float: "right", margin: "7px" }}
            className="btn btn-danger"
          >
            Logout
          </button>
          <button
            onClick={() => {
              this.closeModal(false);
            }}
            style={{ float: "right", margin: "7px" }}
            className="btn btn-primary"
          >
            Cancel
          </button>
        </Modal>

        <form onSubmit={this.hanldeKeySubmit.bind(this)}>
          <table className="noshadow noborder">
            <tbody>
              <tr className="noborder">
                <td className="noborder">Gitlab Personal Access Token</td>
                <td className="noborder">
                  <input
                    className="floatr"
                    type="password"
                    value={this.state.key}
                    onChange={this.handleKeyChange.bind(this)}
                  />
                </td>
              </tr>
              <tr className="noborder">
                <td className="noborder">
                  Base API<br />
                  <span className="italics">
                    e.g. https://gitlab.com/api/v4
                  </span>
                </td>
                <td className="noborder">
                  <span className="floatr">
                    <input
                      type="text"
                      value={this.state.baseval}
                      onChange={this.handleBaseChange.bind(this)}
                    />
                  </span>
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="noborder">
                  <div style={{ textAlign: "center" }} className="italics">
                    AWS DynamoDB is used for Cost Center tags.
                  </div>
                </td>
              </tr>
              <tr className="noborder">
                <td className="noborder">AWS Access Key ID</td>
                <td className="noborder">
                  <input
                    className="floatr"
                    type="text"
                    value={this.state.access}
                    onChange={this.handleAccessChange.bind(this)}
                  />
                </td>
              </tr>
              <tr className="noborder">
                <td className="noborder">AWS Secret Access Key</td>
                <td className="noborder">
                  <input
                    className="floatr"
                    type="password"
                    value={this.state.secret}
                    onChange={this.handleSecretChange.bind(this)}
                  />
                </td>
              </tr>
              <tr>
                <td className="noborder">AWS Region</td>
                <td className="noborder">
                  <RegionOptions
                    onChange={this.handleRegionChange.bind(this)}
                    region={this.state.region}
                  />
                </td>
              </tr>
              <tr className="noborder">
                <td className="noborder">Logo URL</td>
                <td className="noborder">
                  <input
                    className="floatr"
                    type="text"
                    value={this.state.imageURL}
                    onChange={this.handleImageURLChange.bind(this)}
                  />
                </td>
              </tr>
              <tr className="noborder">
                <td className="noborder">
                  Ignored usernames<br />
                </td>
                <td className="noborder">
                  <span className="floatr">
                    <Select
                      className="selectspansmall selectspan"
                      placeholder="Search by name"
                      multi={true}
                      name="usernames"
                      value={this.state.selectusers}
                      onChange={this.handleSelectChange.bind(this)}
                      options={(() => {
                        let opts = [];
                        if (!this.props.users) {
                          return;
                        }
                        for (let user of this.props.users) {
                          opts.push({
                            value: user.username,
                            label: user.name
                          });
                        }
                        return opts;
                      })()}
                    />
                  </span>
                </td>
              </tr>

              <tr>
                <td className="noborder" colSpan="2">
                  <input
                    type="submit"
                    className="btn submitter btn-primary"
                    value={this.state.btnval}
                  />
                </td>
              </tr>
              <tr>
                <td className="noborder" colSpan="2">
                  <button
                    onClick={this.openModal.bind(this)}
                    className="btn submitter btn-danger"
                  >
                    Log Out
                  </button>
                </td>
              </tr>
              <tr>
                <td className="noborder" colSpan="2">
                  <div style={{ textAlign: "center" }} className="italics">
                    All data is stored in your browser.
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    );
  }
}
