import React from "react";
import config from "../config";

//The table used to display the monthly report for each user
export default class DisplayTable extends React.Component {
  constructor(props) {
    super(props);
    let projectNames = [];
    for (let pid in this.props.data) {
      projectNames[pid] = "Project " + pid;
    }
    let projectURLs = [];
    for (let pid in this.props.data) {
      projectURLs[pid] = "#";
    }
    this.state = {
      projectNames: projectNames,
      projectURLs: projectURLs,
    };
    let thData = ["Hours", "Estimate"];
    for (let i = 1; i <= 9; i++) {
      thData.push("0" + i);
    }
    for (let i = 10; i <= 31; i++) {
      thData.push(i);
    }
    this.thElements = [<th>Issues Handled</th>];
    let thEl = thData.map((item) => {
      return <th className="ctd">{item}</th>;
    });
    this.thElements = this.thElements.concat(thEl);
    config.KEY = this.props.pkey;
    config.BASE = this.props.base;
  }

  render() {
    this.contentElement = [];
    for (let pid in this.props.data) {
      let project = this.props.data[pid];
      let innerElements = [];
      if (project.issues.length == 0) {
        continue;
      }
      innerElements.push(
        <tr key={pid}>
          <td colSpan="34" className="projectname">
            <a href={this.props.pnames[pid].url}>
              <div>{this.props.pnames[pid].name}</div>
            </a>
          </td>
        </tr>
      );
      for (let issue of project.issues) {
        let tdElements = [];
        let clname = "badge badge-pill badge-primary";
        if (issue.type == "bug") {
          clname = "badge badge-pill badge-danger";
        }
        let dueDate = "date not set";
        if (issue.duedate !== undefined && issue.duedate !== null) {
          const month = new Date(issue.duedate).toLocaleString("default", {
            month: "short",
          });
          const day = new Date(issue.duedate).getDate();
          const year = new Date(issue.duedate)
            .getFullYear()
            .toString()
            .substr(-2);
          dueDate = day + "-" + month + "-" + year;
        }

        innerElements.push(
          <tr>
            <td colSpan="34">
              <div>
                <span
                  style={{ marginLeft: "7px", marginRight: "7px" }}
                  className="issuetitle"
                >
                  <a href={issue.weburl}>{issue.title}</a>
                </span>
                <span className={clname}>{issue.type}</span>
                <span style={{ marginLeft: "8px" }} className="dueon">
                  {"[Due: " + dueDate + "]"}
                </span>
              </div>
            </td>
          </tr>
        );

        let totalHours = 0;
        let dateElements = [];
        for (let day = 1; day <= 31; day++) {
          totalHours += issue.dateData[day];
          let ncn = "";
          console.log("issueday", toString(issue.dateData[day]));
          let dta = issue.dateData[day] + "";
          dta = dta.substr(0, 4);
          if (issue.dateData[day] == 0) {
            ncn = "greyout";
            dta = "-";
          }
          let cornerFlag = {};
          if (issue.colorData[day] != "#fff") {
            cornerFlag = {
              background: issue.colorData[day],
            };
          }

          dateElements.push(
            <td
              valign="center"
              className={ncn + " ctd small"}
              style={cornerFlag}
              key={day}
            >
              {dta}
            </td>
          );
        }
        let stateClass = "badge badge-pill badge-danger";
        if (issue.state == "opened") {
          issue.state = "open";
          stateClass = "badge badge-pill badge-success";
        }
        let td = (
          <td className={issue.flag}>
            <span className={stateClass}>{issue.state}</span>
          </td>
        );
        if (issue.flag && totalHours != 0) {
          td = (
            <td className="nodue" style={{ background: "#FFCC80" }}>
              <span className={stateClass} style={{ marginLeft: "7px" }}>
                {issue.state}
              </span>
            </td>
          );
        } else {
          td = (
            <td className="">
              <span className={stateClass} style={{ marginLeft: "7px" }}>
                {issue.state}
              </span>
            </td>
          );
        }
        tdElements.push(td);
        totalHours = Math.round(totalHours);
        if (totalHours < 0.5) {
          totalHours = 0;
        }
        tdElements.push(<td className="ctd small">{totalHours}</td>);
        tdElements.push(<td className="ctd small">{issue.estimate}</td>);
        tdElements = tdElements.concat(dateElements);
        innerElements.push(<tr className="hoverdark">{tdElements}</tr>);
      }

      this.contentElement.push(innerElements);
    }
    return (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th colSpan="34">
                <strong>{this.props.name}</strong>
              </th>
            </tr>
            {this.thElements}
          </thead>
          <tbody>{this.contentElement}</tbody>
        </table>
      </div>
    );
  }
}
