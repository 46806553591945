import config from '../config';
import getTableName from './getTableName';

//to update a Cost centre tag in the dynamodb database
export default function(pid,ccname,callback){
    console.log(pid,ccname);
    let docClient = new AWS.DynamoDB.DocumentClient();
    getTableName().then(tableName => {
        var params = {
            TableName:tableName,
            Key:{
                "pid": parseInt(pid),
            },
            UpdateExpression: "set ccname = :c",
            ExpressionAttributeValues:{
                ":c":ccname,
            },
            ReturnValues:"UPDATED_NEW"
        };
    
        docClient.update(params,(err,data)=>{
            console.log("some update happenedas");
            callback(err,data);
        });
    });
    
}