import config from '../config';
import getTableName from './getTableName';

export default function (dynamodb,callback){
    getTableName().then(tableName => {
        let params = {
            TableName:tableName,
            KeySchema:[
                {AttributeName:"pid",KeyType:"HASH"}
            ],
            AttributeDefinitions: [
                { AttributeName: "pid", AttributeType: "N" }
            ],
            ProvisionedThroughput: {
                ReadCapacityUnits: 1,
                WriteCapacityUnits: 1
            }
        }
    
        dynamodb.createTable(params, function(err, data) {

            if (err) {
                console.log("Table error",err);
            } else {
                console.log("Table Made",data);
            }
            callback();
        });
    });
    

}