export const logInError = () => ({
    type : 'LOGIN_ERROR',
});

export const UnauthorizedUser = () => ({
    type : 'UNAUTHORIZED_USER'
});

export const showError = (msg) => ({
      type : 'SHOW_ERROR',
      payload : msg
});

export const getKeyBase = (keyVal,base) => ({
    type : 'GET_KEY_BASE',
    payload : [keyVal,base]
});

export const getAllUsersStored = (users,pnames) => ({
    type : 'GET_ALL_USER',
    payload : [users,pnames]
});

// App Component AcionCreators

export const displayMonthly = () => ({
    type : 'DISPLAY_MONTHLY'
});

export const displayDaily = () => ({
    type : 'DISPLAY_DAILY'
});

export const displayCostCenter = () => ({
    type : 'DISPLAY_CC'
});

export const displaySettings = () => ({
    type : 'DISPLAY_SETTINGS'
});

export const monthlyInput = (data) => ({
    type : 'MONTHLY_INPUT',
    payload : data
});

// Action for ccComponent

export const getCCError = (err) => ({
    type : "CC_ERROR",
    payload : err
});

export const getCCData = (data) => ({
    type : "CC_DATA",
    payload : data
});

export const setReport = (report) => ({
    type : "SET_REPORT",
    payload : report
});

export const emptyReport = () => ({
    type : "EMPTY_REPORT"
});

export const setFullReport = (report) => ({
    type : "FULL_REPORT",
    payload : report
});

export const emptyFullReport = () => ({
    type : "EMPTY_FULL_REPORT",
});