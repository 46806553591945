import React, { Component } from "react";
import { Store, get, set, clear } from "idb-keyval";

import "react-select/dist/react-select.css";

//Settings form only asking personall access token and base API
export default class MiniSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "",
      baseval: "",
      endpoint: "",
      secret: "",
      access: "",
      region: "us-east-2",
      baseval: "",
      imageURL: "",
      filter: "",
      selectusers: [],
      btnval: "Submit"
    };
    this.customStore = new Store("gitlab-reporter-db", "gitlab-reporter-store");
  }
  componentDidMount() {
    get("gitlab-pkey", this.customStore)
      .then(key => {
        get("gitlab-base", this.customStore).then(base => {
          this.setState({
            key: key,
            baseval: base
          });
        });
      })
      .catch(err => console.log("It failed!", err));
  }
  hanldeKeySubmit(evt) {
    evt.preventDefault();
    console.log("state>", this.state);
    set("gitlab-pkey", this.state.key, this.customStore)
      .then(() => {
        set("gitlab-base", this.state.base || this.state.baseval , this.customStore).then(() => {
          location.reload();
        });
      })
      .catch(err => console.log("It failed!", err));
  }
  handleKeyChange(evt) {
    this.setState({ key: evt.target.value.trim() });
  }
  handleBaseChange(evt) {
    this.setState({
      baseval: evt.target.value.trim(),
      base: evt.target.value.trim()
    });
  }

  // handleLogOut(evt) {
  //   evt.preventDefault();
  //   if (!confirm("Are you sure?")) {
  //     console.log("Here!!!");
  //     console.clear();
  //     return;
  //   }
  //   this.setState({
  //     key: "",
  //     baseval: "",
  //     endpoint: "",
  //     secret: "",
  //     access: "",
  //     region: "us-east-2",
  //     baseval: "",
  //     imageURL: "",
  //     filter: ""
  //   });
  //   clear(this.customStore);
  //   location.reload();
  // }
  render() {
    return (
      <div
        style={{
          marginTop: "10%"
        }}
        className="card"
      >
        <form onSubmit={this.hanldeKeySubmit.bind(this)}>
          <table className="noshadow noborder">
            <tbody>
              <tr className="noborder">
                <td className="noborder">Gitlab Personal Access Token</td>
                <td className="noborder">
                  <input
                    className="floatr"
                    type="password"
                    value={this.state.key}
                    onChange={this.handleKeyChange.bind(this)}
                  />
                </td>
              </tr>
              <tr className="noborder">
                <td className="noborder">
                  Base API<br />
                  <span className="italics">
                    https://gitlab.com/api/v4
                  </span>
                </td>
                <td className="noborder">
                  <span className="floatr">
                    <input
                      type="text"
                      value={this.state.baseval}
                      onChange={this.handleBaseChange.bind(this)}
                    />
                  </span>
                </td>
              </tr>
              <tr>
                <td className="noborder" colSpan="2">
                  <input
                    type="submit"
                    className="btn submitter btn-primary"
                    value={this.state.btnval}
                  />
                </td>
              </tr>

              <tr>
                <td className="noborder" colSpan="2">
                  <div style={{ textAlign: "center" }} className="italics">
                    All data is stored in your browser.
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    );
  }
}
