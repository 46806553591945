import React, { Component } from "react";
import Report from "./Report";
import Header from "../components/Header";
import LegendItem from "../components/LegendItem";
import config from "../config";
import { CSVLink } from "react-csv";
import dailyPrint from "../printutil/dailyPrint";

import { setReport, setFullReport } from "../Actions/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//holder for multiple report instances -  reports for multiple users one by one
class MultiReport extends Component {
  constructor(props) {
    super(props);
    this.fR = [["Name", "Salary", "Type", "Cost Center", "Allocation %"]];
    this.numGot = 0;
    this.totalIDs = this.props.data.userids.length;
  }

  handleReportDone(id) {
    if (id < this.props.data.userids.length) {
      this.props.setReport(
        <Report
          pnames={this.props.userReducer.pnames}
          issuelist={this.props.issuelist}
          total={this.props.data.userids.length}
          done={this.handleReportDone.bind(this)}
          ccdata={this.props.ccReducer.ccData}
          addreport={this.addReport.bind(this)}
          unique={id}
          base={this.props.userReducer.base}
          showAllStats={this.props.appReducer.showAllStats}
          showDate={this.props.appReducer.showDate}
          onlydate={this.props.data.onlydate}
          pkey={this.props.userReducer.keyVal}
          key={id}
          tags={this.props.data.tags}
          userid={this.props.data.userids[id]}
          name={this.props.data.names[id]}
          mode="multi"
          month={this.props.data.month}
          year={this.props.data.year}
        />
      );
    }
  }

  addReport(op, data, sentOnce) {
    if (!sentOnce) {
      this.numGot++;
    }
    if (op !== "del") {
      this.fR = this.fR.concat(data);
    }

    console.log("report-add list", this.fR);
    console.log("report-add data", data);
    console.log("numgotuser", data, this.totalIDs, this.numGot);

    if (this.totalIDs == this.numGot) {
      console.log();
      this.props.setFullReport(this.fR);
    }
  }

  componentDidMount() {
    this.handleReportDone(0);
  }

  shouldComponentUpdate = (nextProps) => nextProps !== this.props;

  render() {
    let textmonths = [
      "",
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let styleElem = (
      <style type="text/css">
        {`@media print{@page {size: landscape}
          th{
            padding:0.3em !important;
            padding-top:0em !important;
          }
          .projectname{
            padding:0.3em !important;
            background: #e9e9e9;
          }
        }  .report{
              page-break-after: always;
          }
          `}
      </style>
    );
    if (this.props.appReducer.showDate || this.props.appReducer.showAllStats) {
      styleElem = (
        <style type="text/css">
          {`@media print
        {@page {size: portrait;}
        }`}
        </style>
      );
    }
    let headerElement;
    let fixHeader;
    let legendElement = (
      <div className="legend">
        <LegendItem title="Due on" color={config.colors.dueon} />
        <br />
        <LegendItem title="Due date met" color={config.colors.duemet} />
        <br />
        <LegendItem title="Due date missed" color={config.colors.duemiss} />
        <br />
        <LegendItem title="No due date set" color={config.colors.nodue} />
      </div>
    );
    if (this.props.appReducer.showDate) {
      legendElement = (
        <div className="legend">
          <LegendItem title="No due date set" color={config.colors.nodue} />
          <LegendItem title="No records entered" color={"#ffcccc"} />
          <button
            onClick={() => {
              dailyPrint(this.props);
            }}
            className="btn btn-success"
          >
            <i style={{ color: "#fff" }} className="fas fa-download" /> Download
            PDF
          </button>
        </div>
      );
    }
    let csvEl = (
      <span>
        {this.props.ccReducer.ccError}
        <button className="btn btn-success dcsv">
          <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
          </div>
        </button>
      </span>
    );

    if (this.props.appReducer.showAllStats && this.props.userReducer.fullLoad) {
      legendElement = <span />;
      csvEl = (
        <span>
          {this.props.ccReducer.ccError}
          <CSVLink
            filename={`COST-REPORT.csv`}
            data={this.props.userReducer.fullReport}
          >
            <button className="btn btn-success">
              <i style={{ color: "#fff" }} className="fas fa-download" />{" "}
              Download CSV
            </button>
          </CSVLink>
        </span>
      );
    }
    if (this.props.appReducer.showDate) {
      if (this.props.issuelist) {
        fixHeader = (
          <Header
            history={this.props.history}
            dispatch={this.props.dispatch}
            pkey={this.props.userReducer.keyVal}
            users={this.props.userReducer.users}
            base={this.props.userReducer.base}
            showMenu={true}
            sideOpts={this.props.sideOpts}
            legend={legendElement}
            goback={this.props.goback}
            className="screenheader"
            text={`Issue List for ${this.props.data.onlydate}-${
              textmonths[this.props.data.month]
            }-${this.props.data.year}`}
          />
        );
      } else {
        fixHeader = (
          <Header
            history={this.props.history}
            dispatch={this.props.dispatch}
            pkey={this.props.userReducer.keyVal}
            users={this.props.userReducer.users}
            base={this.props.userReducer.base}
            showMenu={true}
            sideOpts={this.props.sideOpts}
            legend={legendElement}
            goback={this.props.goback}
            className="screenheader"
            text={`Report for ${this.props.data.onlydate}-${
              textmonths[this.props.data.month]
            }-${this.props.data.year}`}
          />
        );
      }
    } else
      fixHeader = (
        <Header
          history={this.props.history}
          dispatch={this.props.dispatch}
          pkey={this.props.userReducer.keyVal}
          users={this.props.userReducer.users}
          base={this.props.userReducer.base}
          showMenu={true}
          sideOpts={this.props.sideOpts}
          legend={legendElement}
          goback={this.props.goback}
          className="screenheader"
          text={`Report for ${textmonths[this.props.data.month]} ${
            this.props.data.year
          }`}
        />
      );

    if (this.props.appReducer.showDate) {
      headerElement = (
        <thead>
          <tr>
            <th className="reporttd reportth ctd">
              <span className="printtext">
                {`Report for ${this.props.data.onlydate}-${
                  textmonths[this.props.data.month]
                }-${this.props.data.year}`}
              </span>
              <br />
              <span className="printlonly">{legendElement}</span>
            </th>
          </tr>
        </thead>
      );
    } else
      headerElement = (
        <thead>
          <tr>
            <th className="reporttd reportth ctd">
              <span className="printtext">{`Report for ${
                textmonths[this.props.data.month]
              } ${this.props.data.year}`}</span>
              <br /> <span className="printlonly">{legendElement}</span>
            </th>
          </tr>
        </thead>
      );

    // console.log("csv el",csvEl);
    if (this.props.appReducer.showAllStats) {
      fixHeader = (
        <Header
          dispatch={this.props.dispatch}
          history={this.props.history}
          pkey={this.props.pkey}
          users={this.props.users}
          base={this.props.base}
          showMenu={true}
          sideOpts={this.props.sideOpts}
          legend={csvEl}
          goback={this.props.goback}
          className="screenheader"
          text={`Cost Center Report for ${textmonths[this.props.data.month]} ${
            this.props.data.year
          }`}
        />
      );

      headerElement = (
        <thead>
          <tr>
            <td className="reporttd reportth ctd">
              <span className="printtext">{`Cost Center Report for ${
                textmonths[this.props.data.month]
              } ${this.props.data.year}`}</span>
            </td>
          </tr>
        </thead>
      );
    }

    if (this.props.appReducer.showDate) {
      return (
        <section>
          {fixHeader}
          <table className="strechtable">
            {headerElement}
            {styleElem}
            <tbody>
              <tr>
                <td className="reporttd">
                  <div id="test" style={{ height: "10pt" }} />
                  {this.props.userReducer.reportElements}
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      );
    }
    return (
      <section>
        {fixHeader}
        <table className="strechtable">
          {headerElement}
          {styleElem}
          <tbody>
            <tr>
              <td className="reporttd monthlytd">
                <div id="test" style={{ height: "10pt" }} />
                {this.props.userReducer.reportElements}
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userReducer: state.userReducer,
    appReducer: state.appReducer,
    ccReducer: state.ccReducer,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setReport,
      setFullReport,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MultiReport);
