import React from "react";
import { CSVLink } from "react-csv";

export default class StatsDisplay extends React.Component {
  constructor(props) {
    super(props);
    console.log("purls", this.props);

    this.dataArray = [];
    console.log("funcreport3", this.props.addreport);

    this.sentArray = [];
    for (let pid in this.props.data) {
      this.sentArray[pid] = false;
    }
    this.sentOnce = false;

    console.log("pnames in stats display", this.props.pnames);
  }
  render() {
    this.projectStats = [];
    this.csvData = [];
    let printData = [];
    let totalProjectTime = 0;
    let Tags = this.props.tags;
    let colors = ["success","warning","info","dark"];
    let progessColors = [];
    
    for (let pid in this.props.data) {
      let project = this.props.data[pid];
      if (project.issues.length == 0) {
        continue;
      }
      let totalValues = {};
      for(let tag of Tags)
      {
        totalValues[tag] = 0;
      }
      let totalHours = 0;
      console.log("pid>>>", pid);
      for (let issue of project.issues) {
        console.log(issue);
          for (let day = 1; day <= 31; day++) {
            totalHours += issue.dateData[day];
            let found = false;
            for(let tag of Tags) {
            if(issue.type === tag){
              totalValues[tag] += issue.dateData[day];
              found = true;
              break;
            }
          }
          if(!found){
            totalValues['unknown'] += issue.dateData[day];
          }
        }
      }
      if(totalValues['unknown'] || Number.isNaN(totalValues['unknown'])){
        Tags.push('unknown')
      }
      Tags = Array.from(new Set(Tags));
      let j = 0;
      for(let i = 0;i<Tags.length;i++){
        if(Tags[i] == 'bug'){
          progessColors['bug'] = "danger";
        }
        else if(Tags[i] == 'feature'){
          progessColors['feature'] = "primary";
        }
        else{
          progessColors[Tags[i]] = colors[j];
          j++;
        }
      }
      totalProjectTime += totalHours;
      let percentageValue = [];
      for (let i =0;i<Tags.length;i++){
        percentageValue[Tags[i]] = Math.round((totalValues[Tags[i]]/totalHours)*100);
      }
      let allZeroes = true;
      for(let tag of Tags){
        if(Number.isNaN(percentageValue[tag])){
          percentageValue[tag] = 0;
        }
        if(percentageValue[tag] > 0 ){
          allZeroes = false;
        }
      }

      if(allZeroes){
          continue;
      }
      this.projectStats[pid] = percentageValue;
    }
    
    let fullData = [];
    let totalPerc = 0.0;
    for (let pid in this.props.data) {
      let totalValues = {};
      for(let tag of Tags)
      {
        totalValues[tag] = 0;
      }
      printData[pid] = totalValues;
      fullData[pid] = totalValues;
      console.log("pid1>>>", pid, printData[pid],fullData[pid] );
      let project = this.props.data[pid];
      if (project.issues.length == 0) {
        continue;
      }

      for (let issue of project.issues) {
        console.log(issue);
          for (let day = 1; day <= 31; day++) {
            let found = false;
            for(let tag of Tags) {
                if(issue.type === tag){
                  totalValues[tag] += issue.dateData[day];
                  found = true;
                  break;
                }
          }
          if(!found){
            totalValues['unknown'] += issue.dateData[day];
          }
        }
      }
      for(let tag of Tags){
        printData[pid][tag] = (totalValues[tag] / totalProjectTime) * 100 + "";
        printData[pid][tag] = printData[pid][tag].substr(0, 4);
        totalPerc += parseFloat(printData[pid][tag].substr(0, 4));

        console.log("total", totalPerc);
        if (100.0 - totalPerc < 1.0 && tag == Tags[Tags.length-1] ) {
          printData[pid][tag] =
            parseFloat(printData[pid][tag]) + (100.0 - totalPerc);
        }
      }

    }
    console.log("full data", fullData);

    console.log(printData);
    this.tableRows = [];
    this.hiddenTable = [];
    this.hiddenTable.push(
      <tr>
        <td className="zeropad" align="center">
          <b>Name</b>
        </td>
        <td className="zeropad" align="center">
          <b>Salary</b>
        </td>
        <td className="zeropad" align="center">
          <b>Type</b>
        </td>
        <td className="zeropad" align="center">
          <b>Cost Center</b>
        </td>
        <td className="zeropad" align="center">
          <b>Allocation %</b>
        </td>
      </tr>
    );
    this.csvData.push([
      "Name",
      "Salary",
      "Type",
      "Cost Center",
      "Allocation %"
    ]);

    if (this.projectStats.length == 0) {
      this.props.addreport("del", [], this.sentOnce);
      this.sentOnce = true;
    }
    for (let pid in this.projectStats) {
      let wdDiv = {};
      let pdTd = {};
      if (this.props.showName) {
        wdDiv = {
          width: "300px",
          textAlign: "left"
        };
        pdTd = {
          paddingRight: "0px"
        };
      }
      console.log("pid#->", pid);
      let showName = this.props.ccdata;
      if (!showName) {
        showName = this.props.pnames[pid].name;
      } else {
        showName = this.props.ccdata[pid];
      }
      if (this.props.pnames[pid]) {
        this.tableRows.push(
          <tr key={pid}>
            <td style={pdTd} className="projectname ltd">
              <a href={this.props.pnames[pid].url}>
                <div style={wdDiv}>{showName}</div>
              </a>
            </td>
            {Tags.map((tag)=>(
              <td className="rtd">{this.projectStats[pid][tag]}%</td>
            ))}
            <td className="sharecenter">
              <div className="progress statsbar">
                {Tags.map((tag,index)=>(
                  <div
                  className={`progress-bar progress-bar-striped bg-${progessColors[tag]}`}
                  role="progressbar"
                  style={{ width: `${this.projectStats[pid][tag]}%` }}
                  aria-valuenow="15"
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
                ))}
              </div>
            </td>
          </tr>
        );

        if (this.props.showName) {
          let allZeroes = true;
            for(let tag of  Tags){
                this.hiddenTable.push(
                  <tr>
                    <td>{this.props.name}</td>
                    <td> </td>
                    <td>{tag}</td>
                    <td>{this.props.ccdata[pid]}</td>
                    <td>{printData[pid][tag]}</td>
                  </tr>
                );
                this.csvData.push([
                  this.props.name,
                  "",
                  `${tag}`,
                  this.props.ccdata[pid],
                  printData[pid][tag]
                ]);
                if(this.projectStats[pid][tag] > 0 ){
                  allZeroes = false;
                }
            }
          if (
            !this.sentArray[pid] &&
            this.props.ccloaded &&
            !(
              allZeroes
            )
          ) {
            let report = [];
            for(let tag of Tags){
              report.push([
                this.props.name,
                "",
                `${tag}`,
                this.props.ccdata[pid],
                printData[pid][tag]
              ],)
            }
            this.props.addreport(
              "",
              report,
              this.sentOnce
            );
            this.sentOnce = true;
            this.sentArray[pid] = true;
          }
        }
      } else {
        this.tableRows.push(
          <tr key={pid}>
            <td className="projectname">Project {pid}</td>
            {Tags.map((tag)=>(
              <td className="bugperc">{this.projectStats[pid][tag]}%</td>
            ))}
          </tr>
        );

        if (this.props.showName) {
          for(let tag of  Tags){
            this.hiddenTable.push(
              <tr>
                <td className="zeropad">{this.props.name}</td>
                <td className="zeropad"> </td>
                <td className="zeropad">Bug</td>
                <td className="zeropad">{this.props.ccdata[pid]}</td>
                <td className="zeropad">{printData[pid][tag]}</td>
              </tr>
            );
            this.csvData.push([
              this.props.name,
              "",
              `${tag}`,
              this.props.ccdata[pid],
              printData[pid][tag]
            ]);
          }
        }
      }
    }

    let nameHead;
    let wdClass = "";
    console.log("showname", this.props.showName);
    console.log("csv data", this.csvData);
    if (this.props.showName) {
      nameHead = (
        <tr>
          <th className="nameth" colSpan="15">
            <div className="namehead">
              <span className="namepush">{this.props.name}</span>

              <span className="copybutton">
                <i
                  className="far fa-copy padit"
                  onClick={evt => {
                    evt.preventDefault();
                    console.log("unique", this.props.unique);
                    if (document.selection) {
                      //IE
                      var range = document.body.createTextRange();
                      range.moveToElementText(
                        document.getElementById(
                          "popularity-" + this.props.unique
                        )
                      );
                      range.execCommand("Copy");
                      document.selection.empty();
                    } else if (window.getSelection) {
                      //others
                      window.getSelection().removeAllRanges();
                      var range = document.createRange();
                      console.log(
                        "popu",
                        document.getElementById(
                          "popularity-" + this.props.unique
                        )
                      );
                      range.selectNode(
                        document.getElementById(
                          "popularity-" + this.props.unique
                        )
                      );

                      console.log("range", range);
                      window.getSelection().addRange(range);

                      document.execCommand("copy");
                      console.log("selection", window.getSelection());

                      window.getSelection().removeAllRanges();
                    }
                  }}
                />
                <CSVLink
                  filename={`${this.props.name}-COST-REPORT.csv`}
                  data={this.csvData}
                >
                  <a>
                    <i className="fas fa-download padit" />
                  </a>
                </CSVLink>
              </span>
            </div>
          </th>
        </tr>
      );

      wdClass = "dstable";
    }
    console.log("trows>", this.tableRows);
    let wdDiv = {};
    if (this.props.showName) {
      wdDiv = {
        width: "204px",
        textAlign: "left"
      };
    }
    if (this.projectStats.length == 0) {
      return (
        <div className="statsdisplay">
          <table className={wdClass}>
            {nameHead}
            <tbody>
              <tr>
                <td colSpan="5" className="ctd">
                  No information recorded
                </td>
              </tr>
            </tbody>
          </table>
          <table id={"popularity-" + this.props.unique} className="hiddentable">
            {this.hiddenTable}
          </table>
        </div>
      );
    }
    return (
      <div className="statsdisplay">
        <table className={wdClass}>
          {nameHead}
          <tr>
            <th className="ltd">
              <div style={wdDiv}>Repo</div>
            </th>
            {Tags.map((val,index)=>(
              <th className={`rtd text-${progessColors[val]}`}>{val.charAt(0).toUpperCase() + val.slice(1)}</th>
            ))}
            <th className="rtd">Ratio</th>
          </tr>
          <tbody>{this.tableRows}</tbody>
        </table>
        <table id={"popularity-" + this.props.unique} className="hiddentable">
          {this.hiddenTable}
        </table>
      </div>
    );
  }
}
