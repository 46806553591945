import React from "react";
import config from '../config';
import getIssueType from '../util/getIssueType';
import prepNotesURLs from '../util/prepNotesURLs';
import combineMapping from '../util/combineMapping';
import parseNotes from '../util/parseNotes';
import DisplayTable from '../components/DisplayTable';
import StatsDisplay from '../components/StatsDisplay';
import DisplayDayStats from '../components/DisplayDayStats';
import NamedStatsDisplay from '../components/NamedStatsDisplay';

//holder for any kind of report fora single user
export default class Report extends React.Component {
    constructor(props) {
        super(props);

        if (this.props.mode == 'multi') {
            console.log("report multi");
            this.state = {
                userid: parseInt(this.props.userid),
                month: parseInt(this.props.month),
                year: parseInt(this.props.year),
                loaded: false,
                issueToNotes: [],
                globalMapping: [],
                name: this.props.name,
                purls: [],
                error: false,
                errorMsg: ''
            };
        } else {
        }

        this.issueNextPage = -1;
        this.issueTotal = -1;
        this.issueMapping = [];
        this.noteNextPage = [];
        this.noteTotal = [];
        this.issueToProj = [];
        this.issues = [];
        config.KEY = this.props.pkey;
        config.BASE = this.props.base;
        console.log("pnames in report", this.props.pnames);
        this.loadnext = true;
    }
    getAllIssues(startPage) {
        const assgnURL = `${config.BASE}/issues?assignee_id=${this.state.userid}&per_page=100&page=${startPage}&scope=all&timestamp=${new Date().getTime()}`;
        return fetch(assgnURL, {
            headers: {
                'PRIVATE-TOKEN': config.KEY
            }
        }).then(response => {
            if (response.status == 401) {
                this.setState({ error: true, errorMsg: 'Check Gitlab Personal Access Token' });
                return;
            }

            for (let pair of response.headers.entries()) {
                console.log(pair);
                if (pair[0] == 'x-next-page') {
                    this.issueNextPage = parseInt(pair[1]);
                }
                if (pair[0] == 'x-total-pages') {
                    this.issueTotal = parseInt(pair[1]);
                }
            }
            return response.json();
        }).then(data => {

            for (let d of data) {
                this.issues.push(d);
            }
            if (this.issueTotal > startPage) {
                return this.getAllIssuePages();
            }
        }).catch(error => {
            console.log("catch error", error);
            this.setState({ error: true });
        });;
    }
    getAllIssuePages() {
        let allURL = [];
        for (let i = this.issueNextPage; i <= this.issueTotal; i++) {
            allURL.push(`${config.BASE}/issues?assignee_id=${this.state.userid}&per_page=100&page=${i}&timestamp=${new Date().getTime()}`);
        }
        return Promise.all(allURL.map(url =>
            fetch(url, {

                headers: {
                    'PRIVATE-TOKEN': config.KEY
                }
            }).then(resp => {
                if (resp.status == 401) {
                    this.setState({ error: true, errorMsg: 'Check Gitlab Personal Access Token' });
                    return;
                }
                return resp.json();
            })
        )).then(json => {
            console.log("jsn", json);
            for (let jsn of json) {
                for (let sn of jsn) {
                    this.issues.push(sn);
                }
            }
        }).catch(error => {
            console.log("catch error", error);
            this.setState({ error: true });
        });;

    }
    getAllNotes(projectMapping) {
        let urls = prepNotesURLs(projectMapping, config.KEY);
        console.log(urls);
        for (let u of urls) {
            this.issueToProj[u.id] = u.projectid;
        }

        return Promise.all(urls.map(url =>
            fetch(url.url, {
                headers: {
                    'PRIVATE-TOKEN': config.KEY
                }
            }).then(resp => {

                for (let pair of resp.headers.entries()) {

                    if (pair[0] == 'x-next-page') {
                        this.noteNextPage[url.id] = parseInt(pair[1]);
                    }
                    if (pair[0] == 'x-total-pages') {
                        this.noteTotal[url.id] = parseInt(pair[1]);
                    }
                }
                return resp.json();
            }
            )
        )).then(json => {
            console.log(this.noteNextPage, this.noteTotal);
            return Promise.all(
                json.map((jsn) => {
                    console.log(jsn);
                    if (jsn.length == 0) {
                        return;
                    }
                    let nid = parseInt(jsn[0].noteable_id);
                    let totalPages = this.noteTotal[nid];
                    let nextPage = this.noteNextPage[nid];
                    let niid = parseInt(jsn[0].noteable_iid);
                    this.issueMapping[nid] = [];
                    console.log("nid", nid);
                    for (let js of jsn) {
                        this.issueMapping[nid].push(js);
                    }
                    if (nextPage != NaN)
                        return this.getAllNotesPages(nextPage, totalPages, niid, nid, this.issueToProj[nid]);
                })
            );
        }).catch(error => {
            console.log("catch error", error);
            this.setState({ error: true });
        });;
    }
    getAllNotesPages(nextPage, totalPages, iid, nid, pid) {
        let allURL = [];
        console.log(pid);
        for (let i = nextPage; i <= totalPages; i++) {
            allURL.push(`${config.BASE}/projects/${pid}/issues/${iid}/notes?sort=desc&order_by=updated_at&per_page=100&page=${i}&timestamp=${new Date().getTime()}`);
        }
        return Promise.all(allURL.map(url =>
            fetch(url, {
                headers: {
                    'PRIVATE-TOKEN': config.KEY
                }
            }).then(resp => {
                if (resp.status == 401) {
                    this.setState({ error: true, errorMsg: 'Check Gitlab Personal Access Token' });
                    return;
                }

                return resp.json();
            })
        )).then(json => {
            console.log("jsnas", json);
            for (let jsn of json) {
                for (let sn of jsn) {
                    this.issueMapping[nid].push(sn);
                }
            }
        }).catch(error => {
            console.log("catch error", error);
            this.setState({ error: true });
        });
    }
    
    shouldComponentUpdate = (nextProps, nextState) => nextProps !== this.props || nextState !== this.state;

    componentDidMount() {
        this.getAllIssues(1).then(data => {
            console.log("asd>", this.issues);
            let projectMapping = [];
            for (let issue of this.issues) {
                if (!getIssueType(issue)) {
                    continue;
                }
                if (!projectMapping[issue.project_id]) {
                    projectMapping[issue.project_id] =
                        {
                            issues: [{
                                iid: issue.iid,
                                id: issue.id,
                                title: issue.title,
                                type: getIssueType(issue),
                                estimate: ((issue.time_stats.time_estimate / 3600) + '').substr(0, 4),
                                duedate: issue.due_date,
                                state: issue.state,
                                closedAt: issue.closed_at,
                                flag: (issue.due_date == null),
                                weburl: issue.web_url,
                                openedAt: issue.created_at
                            }],
                            title: ''
                        }

                } else {

                    projectMapping[issue.project_id].issues.push(
                        {
                            iid: issue.iid,
                            id: issue.id,
                            title: issue.title,
                            type: getIssueType(issue),
                            estimate: ((issue.time_stats.time_estimate / 3600) + '').substr(0, 4),
                            duedate: issue.due_date,
                            state: issue.state,
                            closedAt: issue.closed_at,
                            flag: (issue.due_date == null),
                            weburl: issue.web_url,
                            openedAt: issue.created_at
                        }
                    );
                }
            }
            console.log(projectMapping);
            this.getAllNotes(projectMapping).then(data => {
                console.log("i->n", this.issueMapping); <div className="progress">
                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}></div>
                </div>
                this.setState({ issueToNotes: this.issueMapping });

                let combinedMapping = combineMapping(projectMapping, this.issueMapping, this.issueToProj);
                let pnotes = parseNotes(combinedMapping, this.state.userid, this.state.month, this.state.year);
                console.log("pnotes", pnotes);
                if (this.loadnext) {
                    this.props.done(parseInt(this.props.unique)+1);
                }

                this.setState({ loaded: true, globalMapping: pnotes });
            }).catch(error => {
                console.log("catch error", error);
                this.setState({ error: true });
            });
        }).catch(error => {
            console.log("catch error", error);
            this.setState({ error: true });
        });

    }

    render() {
        if (this.state.error) {
            return <div><div style={{
                padding: '2em'
            }} className="formerror">ERROR<br />{this.state.errorMsg}<div>
                    <div>
                        <button onClick={() => {
                            this.setState({ error: false, loaded: false });
                            this.componentDidMount();
                            this.loadnext = false;
                        }} className="btn btn-error">Reload {this.props.name}</button></div>
                </div></div></div>;
        }
        if (!this.state.loaded) {
            return (
                <div className="inputform">
                    <div className="progress">
                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}></div>
                    </div><br />
                    <div style={{ textAlign: 'center' }}>Loading {this.props.unique + 1} of {this.props.total}</div>
                </div>
            );
        } else {
            if (this.props.showDate) {
                return (
                    <div className="report">
                        <DisplayDayStats
                            issuelist={this.props.issuelist}
                            pnames={this.props.pnames}
                            base={this.props.base}
                            pkey={this.props.pkey}
                            data={this.state.globalMapping}
                            name={this.state.name}
                            date={this.props.onlydate}
                        />
                    </div>
                );
            }
            if (this.props.showAllStats) {
                console.log("pnames in render report", this.props.pnames);
                return (
                    <div className="report">
                        <NamedStatsDisplay
                            pnames={this.props.pnames}
                            ccdata={this.props.ccdata}
                            addreport={this.props.addreport}
                            base={this.props.base}
                            pkey={config.KEY}
                            name={this.state.name}
                            month={this.state.month}
                            year={this.state.year}
                            data={this.state.globalMapping}
                            unique={this.props.unique}
                            tags={this.props.tags}
                        />

                    </div>
                );
            }
            return (
                <div className="report monthreport">
                    <div className="topholder">
                        <StatsDisplay
                            pnames={this.props.pnames}
                            addreport={this.props.addreport}
                            base={this.props.base}
                            urls={this.state.purls}
                            data={this.state.globalMapping}
                            tags={this.props.tags}
                        />
                    </div>


                    <DisplayTable
                        pnames={this.props.pnames}
                        name={this.state.name}
                        base={this.props.base}
                        pkey={this.props.pkey}
                        data={this.state.globalMapping}
                    />

                </div>
            );
        }

    }

}