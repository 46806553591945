import React, { Component } from "react";
import { Store, get } from "idb-keyval";
import MultiReport from "../MultiReport";
import getAllData from "../../awsutil/getAllData";
import MiniSettingsForm from "../../components/MiniSettingsForm";
import { Link } from "react-router-dom";

import { logInError,displayCostCenter, getCCError, getCCData } from "../../Actions/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class URLMultiCC extends Component {
  constructor(props) {
    super(props);
    this.customStore = new Store("gitlab-reporter-db", "gitlab-reporter-store");
    this.userids = [];
    this.names = [];
    this.month = parseInt(this.props.match.params.month);
    this.year = parseInt(this.props.match.params.year);
    this.tags = [];   
    this.allputData = [];
    if(this.props.match.params.userids != 'allusers'){
      let userParams = this.props.match.params.userids.split(",");
      console.log("params", userParams);
      this.userids = userParams.map(user => {
        return parseInt(user);
      });
      this.names = this.getNamesFromIds();
    }
    else {
      this.userids = this.getUserIds();
      this.names = this.getNames();
    }
    if(this.props.match.params.tags){
      this.tags = this.props.match.params.tags.split(",");
      }
    else{
      this.tags = [];
    }
    this.props.displayCostCenter();
  }
  getUserIds() {
    let ids = [];
    for (let user of this.props.userReducer.users) {
      ids.push(user.id);
    }
    return ids;
  }
  getNames() {
    let names = [];
    for (let user of this.props.userReducer.users) {
      names.push(user.name);
    }
    return names;
  }
  getNamesFromIds() {
    let names = [];
    for (let id of this.userids) {
      for (let user of this.props.userReducer.users) {
        if (id == user.id) {
          names.push(user.name);
        }
      }
    }
    return names;
  }

  componentDidMount() {
    Promise.all([
      get("gitlab-dynamo-access", this.customStore),
      get("gitlab-dynamo-secret", this.customStore),
      get("gitlab-dynamo-endpoint", this.customStore),
      get("gitlab-dynamo-region", this.customStore),
      get("gitlab-pkey", this.customStore),
      get("gitlab-base", this.customStore)
    ]).then(data => {
      let access = data[0];
      let secret = data[1];
      let endpoint = data[2];
      let region = data[3];
      let val = data[4];
      let base = data[5];

      if (val) {
        this.setState({ key: val, loaded: true, base: base });
      } else {
        this.props.logInError();
      }

      AWS.config.update({
        region: region,
        endpoint: endpoint,
        accessKeyId: access,
        secretAccessKey: secret
      });

      getAllData((err, data) => {
        if (err) {
          console.log("scan error", err);
          let ErrorLink = (
                <Link to="/?tab=settings">
                  {" "}
                  <span className="ccerror">
                    <i className="fas fa-exclamation-triangle" /> Check AWS
                    Credentials
                  </span>
                </Link>
              );
          this.props.getCCError(ErrorLink);
        } else {
          console.log("scan data", data);
          let ccMap = [];
          for (let item of data.Items) {
            ccMap[item.pid] = item.ccname;
          }
          this.props.getCCData(ccMap);
        }
      });
    });
  }
  handleBack() {
    this.props.history.push("/");
  }
  render() {
    if (this.props.userReducer.error || this.props.ccReducer.error) {
      return (
        <div>
          <div
            style={{
              padding: "2em"
            }}
            className="formerror"
          >
            ERROR<br />
            {this.props.userReducer.errorMsg}
          </div>
          <br />
          <MiniSettingsForm
            // base={this.props.userReducer.base}
            // pkey={this.props.userReducer.keyVal}
            // dispatch={this.handleMultiInput}
          />
        </div>
      );
    }
    if (!this.props.userReducer.loaded || !this.props.ccReducer.ccload) {
      return (
        <div className="inputform prgrs">
          <div style={{ padding: "1em" }} className="ctd">
            Loading DynamoDB data
          </div>
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      );
    }

    let multiData = {
      userids: this.userids,
      month: this.month,
      year: this.year,
      names: this.names,
      tags: this.tags
    };
    return (
      <MultiReport
        history={this.props.history}
        goback={this.handleBack.bind(this)}
        data={multiData}
      />
    );
  }
}

const mapStateToProps = state => {
	 return{
			userReducer : state.userReducer,
      ccReducer : state.ccReducer
	 } 
	}


const mapDispatchToProps = (dispatch) =>
		bindActionCreators({
      		logInError,
          displayCostCenter,
			    getCCError,
      		getCCData
		}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(URLMultiCC);