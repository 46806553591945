import getTableName from './getTableName';

export default function(pid,callback){
    // console.log(pid,ccname);
    let docClient = new AWS.DynamoDB.DocumentClient();
    getTableName().then(tableName => {
        var params = {
            TableName:tableName,
            Key:{
                "pid": parseInt(pid),
            },
        };
    
        docClient.delete(params,(err,data)=>{
            console.log("some update happenedas");
            callback(err,data);
        });
    });
}