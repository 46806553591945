//Parse the notes and filter out the spend notes only
module.exports = function (notes) {
  if (!notes) return [];
  return notes.filter((note) => {
    // Maybe tokenizing would be a better or a safer way instead of reg ex
    let regMatch =
      note.body.match(/(added|subtracted) (.*?) of time spent/i) != null ||
      note.body.match(/(added|subtracted) \d+h of time spent/i) != null ||
      note.body.match(/(added|subtracted) \d+d of time spent/i) != null ||
      note.body.match(/(added|subtracted) \d+w \d+d \d+h of time spent/i) !=
        null ||
      note.body.match(/(added|subtracted) \d+w \d+d of time spent/i) != null ||
      note.body.match(/(added|subtracted) \d+w \d+h of time spent/i) != null;
    if ((regMatch || note.body == "removed time spent") && note.system) {
      return true;
    } else {
      return false;
    }
  });
};
