//sort projects on the basis of their names
export default function (items = []) {
    return items.sort((a, b) => {
        let nameA = a.projectname.toLowerCase(), nameB = b.projectname.toLowerCase()
        if (nameA < nameB) //sort string ascending
            return -1
        if (nameA > nameB)
            return 1
        return 0
    });
}